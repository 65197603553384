import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {}

  logEvent(eventName: string, eventParams?: Record<string, string>) {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', eventName, eventParams);
    }
  }

  setUserId(userId: string) {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('set', { user_id: userId });
    }
  }

  setRaceGuid(raceGuid: string) {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('set', { race_guid: raceGuid });
    }
  }

  clearUserId() {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('set', { user_id: null });
    }
  }
}
