<div>
  <div [ngClass]="{'prompts-wrapper': !isSearchBar, 'prompts-wrapper-with-fade': isSearchBar}">
    <ng-container *ngIf="renderSwiper && isSearchBar">
      <swiper-container
        class="mySwiper"
        space-between="12"
        slides-per-view="auto"
        mousewheel="true"
        sensitivity="0.1"
        slides-ofset-after="0"
        loop="true"
        loopedSlides="12"
        loop-fill-group-with-blank="false"
        free-mode="true"
        free-mode-momentum="true"
        free-mode-momentumRatio="0.5"
        autoplay='{"delay":30,"disableOnInteraction":true,"stopOnLastSlide":false,"pauseOnMouseEnter":true,"stopOnLastSlide":false}'
        speed="3000"
      >
        <swiper-slide *ngIf="prefix">
          <span style="font-weight: 400; font-size: 16px; color: #FFFFFF; white-space: nowrap; line-height: 22px">
            {{ prefix }}
          </span>
        </swiper-slide>
        <swiper-slide *ngFor="let prompt of prompts">
          <div
            [ngClass]="{'prompt-container': !isSearchBar, 'search-bar-prompt-container': isSearchBar}"
            (click)="onClick(prompt)"
            [ngStyle]="{
              'opacity': disabled ? 0.5 : 1,
              'cursor': disabled ? 'not-allowed' : 'pointer',
              'color': isSearchBar ? '#0B0B0B' : mainColor || '#6271FF'
            }"
          >
            <span class="prompt-text">{{ prompt | translate }}</span>
          </div>
        </swiper-slide>
      </swiper-container>
    </ng-container>

    <ng-container *ngIf="renderSwiper && !isSearchBar">
      <swiper-container
        class="mySwiper"
        space-between="12"
        slides-per-view="auto"
        mousewheel="true"
        sensitivity="0.1"
      >
        <swiper-slide *ngIf="prefix">
          <span style="font-weight: 400; font-size: 16px; color: #FFFFFF; white-space: nowrap; line-height: 22px">
            {{ prefix }}
          </span>
        </swiper-slide>
        <swiper-slide *ngFor="let prompt of prompts">
          <div
            [ngClass]="{'prompt-container': !isSearchBar, 'search-bar-prompt-container': isSearchBar}"
            (click)="onClick(prompt)"
            [ngStyle]="{
              'opacity': disabled ? 0.5 : 1,
              'cursor': disabled ? 'not-allowed' : 'pointer',
              'color': isSearchBar ? '#0B0B0B' : mainColor || '#6271FF'
            }"
          >
            <span class="prompt-text">{{ prompt | translate }}</span>
          </div>
        </swiper-slide>
      </swiper-container>
    </ng-container>
  </div>
</div>
