<div class="login-container" *ngIf="showLogin">
  <app-auth-header [title]="'auth.Welcome back!' | translate" [subTitle]="'auth.Log in or create a free account to bookmark messages and revisit past conversations.' | translate"></app-auth-header>
<!--  <div style="margin-top: 20px; margin-bottom: 24px">-->
<!--    <app-social-login></app-social-login>-->
<!--  </div>-->
<!--  <div class="divider-container">-->
<!--    <div class="line"><mat-divider class="divider"></mat-divider></div>-->
<!--    <div class="divider-text">or</div>-->
<!--    <div class="line"><mat-divider class="divider"></mat-divider></div>-->
<!--  </div>-->
  <div *ngIf="errorMessage">
    <mat-error>
      {{ ('auth.' + errorMessage) | translate }}
    </mat-error>
  </div>
  <div style="margin-top: 24px">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div>
        <span class="input-label">{{ 'auth.Email Address' | translate }}</span>
        <mat-form-field>
          <input matInput id="email" formControlName="email" placeholder="example@neurun.com">
        </mat-form-field>
      </div>
      <div style="margin-top: 16px">
        <span class="input-label">{{ 'auth.Password' | translate }}</span>
        <mat-form-field>
          <input matInput type="password" id="password" formControlName="password">
        </mat-form-field>
      </div>
      <span class="action-button" (click)="onForgotPasswordClick()" style="margin-top: 12px">{{ 'auth.Forgot password?' | translate }}</span>
      <div style="margin-top: 40px; margin-bottom: 16px">
        <app-custom-button type="submit" [title]="'auth.Login' | translate" variant="primary" [disabled]="!loginForm.valid || isLoading"></app-custom-button>
      </div>
    </form>
    <span style="font-weight: 600; font-size: 16px; display: flex; align-items: center; justify-content: center;">{{ 'auth.Don’t have an account?' | translate }} <span class="action-button" style="margin-left: 4px" (click)="onRegisterClick()">
      {{ 'auth.Create one here' | translate }}
    </span></span>
  </div>
</div>
<div *ngIf="loginUrl">
  <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(loginUrl)" width="100%" height="600px" frameborder="0"></iframe>
</div>
<div *ngIf="showRegister">
  <app-register (registerChange)="onRegisterChange($event)" (flowChange)="onFlowChange($event)"></app-register>
</div>
<div *ngIf="showForgotPassword">
  <app-forgot-password (flowChange)="onFlowChange($event)"></app-forgot-password>
</div>
