<div
  [ngStyle]="{'opacity': disabled ? 0.5 : 1, 'cursor': disabled ? 'not-allowed' : 'pointer'}"
  [ngClass]="{'narrow-select-wrapper': isNarrowComponent, 'wide-select-wrapper': !isNarrowComponent}"
>
    <ng-select
      #ngSelect
      [items]="races"
      bindValue="id"
      bindLabel="name"
      class="ng-select custom"
      [clearable]="false"
      [searchable]="false"
      (open)="onOpen()"
      [appendTo]="'body'"
      [(ngModel)]="selectedRace"
      (change)="onRaceChange($event)"
    >
        <ng-template ng-label-tmp let-item="item">
            {{ item?.name | translate }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
            {{ item?.name | translate }}
        </ng-template>
    </ng-select>
</div>
