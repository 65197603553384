import {
  bootstrapApplication,
  createApplication,
} from '@angular/platform-browser';
import { appConfig, StaticTranslateLoader } from './app/app.config';
import { AppComponent } from './app/app.component';
import { createCustomElement } from '@angular/elements';
import { SearchBarComponent } from './app/web-components/search-bar/search-bar.component';
import { APP_INITIALIZER, Provider } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { AuthInterceptor } from './app/interceptors/http-interceptor/auth.interceptor';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import { loadGoogleMapsApi, loadGoogleAnalytics } from './app/libs/google';

registerLocaleData(localeJa, 'ja');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');

registerSwiperElements();

loadGoogleMapsApi();
loadGoogleAnalytics();

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);

const SpinnerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};

(async () => {
  const app = await createApplication({
    providers: [
      provideRouter(routes),
      provideAnimationsAsync(),
      provideHttpClient(withInterceptorsFromDi()),
      provideEnvironmentNgxMask(),
      // KeycloakBearerInterceptorProvider,
      // KeycloakInitializerProvider,
      // KeycloakService,
      SpinnerInterceptorProvider,
      provideTranslateService({
        loader: {
          provide: TranslateLoader,
          useClass: StaticTranslateLoader,
          deps: [HttpClient],
        },
      }),
      provideOAuthClient({
        resourceServer: {
          sendAccessToken: true,
        },
      }),
    ],
  });
  const searchBarElement = createCustomElement(SearchBarComponent, {
    injector: app.injector,
  });
  customElements.define('search-bar', searchBarElement);
})();
