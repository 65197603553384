import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-prompts-list',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgForOf, NgIf, NgStyle, TranslatePipe, NgClass],
  templateUrl: './prompts-list.component.html',
  styleUrl: './prompts-list.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PromptsListComponent implements OnChanges {
  @Input() prompts: string[] = [];
  @Input() prefix: string = '';
  @Input() language: string = '';
  @Input() isSearchBar: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() isMobile?: boolean = false;
  @Input() mainColor?: string;
  @Output() handlePromptClick = new EventEmitter<string>();
  renderSwiper = true;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainColor']?.currentValue) {
      document.documentElement.style.setProperty(
        '--main-color',
        this.mainColor || '#6271FF',
      );
      document.documentElement.style.setProperty(
        '--left-nav-button-color',
        this.mainColor || '#4954ba',
      );
      document.documentElement.style.setProperty(
        '--right-nav-button-color',
        this.mainColor || '#6271FF',
      );
    }
    if (changes['language']) {
      this.renderSwiper = false;
      setTimeout(() => {
        this.renderSwiper = true;
      }, 0);
    }
  }

  onClick(prompt: string) {
    if (this.disabled) return;
    this.handlePromptClick.emit(prompt);
  }
}
