import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { MappedChatMessage, PartnerBotInfo } from '../../../types/models';
import {
  DatePipe,
  NgClass,
  NgForOf,
  NgIf,
  NgStyle,
  TitleCasePipe,
} from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'app-partner-bot-chat',
  standalone: true,
  imports: [
    TranslatePipe,
    NgIf,
    NgStyle,
    NgForOf,
    DatePipe,
    NgClass,
    TitleCasePipe,
    InfiniteScrollDirective,
  ],
  templateUrl: './partner-bot-chat.component.html',
  styleUrl: './partner-bot-chat.component.scss',
})
export class PartnerBotChatComponent implements OnInit {
  @ViewChild('chatContainer', { static: false }) chatContainer!: ElementRef;
  @Input() messages: MappedChatMessage[] = [];
  @Input() partnerBotInfo: PartnerBotInfo | null = null;
  @Input() isChatLoading: boolean = false;
  @Input() isChatHistoryLoading: boolean = false;
  @Input() language: string = '';
  @Input() isUserOnline?: boolean = false;
  @Input() mainColor?: string = '#6271FF';
  @Output() handleCloseChat = new EventEmitter();
  @Output() handleOnUp = new EventEmitter();

  ngOnInit() {
    const root = document.documentElement;
    root.style.setProperty('--main-color', this.mainColor || '#6271FF');
  }

  onUp() {
    this.handleOnUp.emit();
  }

  handleBackClick() {
    this.handleCloseChat.emit();
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  public scrollToBottom(): void {
    setTimeout(() => {
      this.chatContainer?.nativeElement?.scrollTo({
        top: this.chatContainer?.nativeElement?.scrollHeight,
        behavior: 'smooth',
      });
    }, 300);
  }

  convertPartnerUrl(url: string): string {
    try {
      if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
      }
      const hostname = new URL(url).hostname;
      return hostname.startsWith('www.') ? hostname.substring(4) : hostname;
    } catch (error) {
      console.error('Invalid URL:', url);
      return '';
    }
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }
}
