<div [ngClass]="{'hidden': !showMap}" style="position: relative">
  <div style="height: calc(100dvh - 104px); width: 100%;">
    <gmp-map-3d
      #map3d
      mode="hybrid"
      class="map"
      heading="25"
      [range]="centerCoordinate ? 2500 : 15000"
      tilt="45">
      <gmp-polyline-3d
        #polyline
        altitude-mode="relative-to-ground"
        stroke-color="#6271FF"
        stroke-width="15"
        stroke-height="5">
      </gmp-polyline-3d>
    </gmp-map-3d>
  </div>
</div>
