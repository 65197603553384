import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  DatePipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgStyle,
  TitleCasePipe,
} from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PromptsListComponent } from '../../components/prompts-list/prompts-list.component';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { ChatService } from '../../services/chat/chat.service';
import {
  ChatMessage,
  ChatMessageLink,
  GearChatMessage,
  GpxFile,
  GpxWaypoints,
  MappedChatMessage,
  OnConnectionReadyResponse,
  PartnerBotInfo,
  PartnerBots,
  Race,
  RaceRadiusData,
  RaceTypes,
  RawSttMessage,
  ScenarioChangeMessage,
  SegmentTracks,
  SttMessage,
  Track,
  TrackCoordinates,
} from '../../../types/models';
import {
  ACCESS_TOKEN,
  ADDITIONAL_PROMPTS_LIST,
  AI_REQUEST_TIMEOUT,
  AI_REQUESTS,
  ANALYTICS_EVENTS,
  CHOOSE_SEGMENT_OPTION,
  CONVERSATION_GUID,
  CONVERSATION_SCENARIO,
  EVENT_RACES,
  INTERACTIVE_WIDGETS,
  LINK_ACCOUNTS_IMAGES,
  MAIN_COLOR,
  PARTNER_BOTS_IMAGES,
  PARTNER_PROMPTS_LIST,
  RACE_TYPES,
  REFRESH_TOKEN,
  SELECTED_LANGUAGE,
  SELECTED_RACE_ID,
  SESSION_USER_GUID,
  SET_RADIUS_OPTION,
  SHOW_MAP_OPTION,
  WIDGET_ACCOUNT_LOGO,
  WIDGET_ACCOUNT_SVG,
  WIDGET_ARROW_FORWARD_LOGO,
  WIDGET_ARROW_FORWARD_SVG,
  WIDGET_CLOSE_LOGO,
  WIDGET_CLOSE_SVG,
  WIDGET_PROMPTS_LIST,
} from '../../../constants';
import { RaceService } from '../../services/race/race.service';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { GoogleMapsServiceService } from '../../services/google-maps-service/google-maps-service.service';
import { ElevationProfileComponent } from '../../components/elevation-profile/elevation-profile.component';
import { MapWithRadiusComponent } from '../../components/map-with-radius/map-with-radius.component';
import { MatChip, MatChipSet } from '@angular/material/chips';
import { RouteSwiperComponent } from '../../components/route-swiper/route-swiper.component';
import { RoutesMapComponent } from '../../components/routes-map/routes-map.component';
import { InteractiveMapComponent } from '../../components/interactive-map/interactive-map.component';
import { CustomButtonComponent } from '../../components/custom-button/custom-button.component';
import { clearAsyncInterval, setAsyncInterval } from '../../helpers/intervals';
import { downSampleBuffer, pcmEncode } from '../../helpers/audioStream';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  MatMenu,
  MatMenuContent,
  MatMenuTrigger,
} from '@angular/material/menu';
import { ScreenResizeService } from '../../services/screenResive/screen-resize.service';
import { UserService } from '../../services/user/user.service';
import { OidcServiceService } from '../../services/oidcService/oidc-service.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SvgLogoService } from '../../services/svgLogoService/svg-logo-service';
import { fromEvent, Subscription } from 'rxjs';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LanguageSelectorComponent } from '../../components/language-selector/language-selector.component';
import { RaceSelectorComponent } from '../../components/race-selector/race-selector.component';
import { PartnerBotChatComponent } from '../partner-bot-chat/partner-bot-chat.component';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { environment } from '../../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { BroadcastChannel } from 'broadcast-channel';
import { FitnessAccountSocketService } from '../../services/fitnessAccountSocket/fitnessAccountSocket.service';

type Coordinates = {
  lat: number;
  lng: number;
};

type MappedPathOutput = {
  lat: number;
  lng: number;
  distance?: number;
  elevationGain?: number;
  totalSimilarity?: number;
};

@Component({
  selector: 'app-ai-chat',
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    ReactiveFormsModule,
    FormsModule,
    PromptsListComponent,
    DatePipe,
    ElevationProfileComponent,
    MapWithRadiusComponent,
    MatCell,
    MatCellDef,
    MatChip,
    MatChipSet,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    NgForOf,
    RouteSwiperComponent,
    RoutesMapComponent,
    MatColumnDef,
    NgStyle,
    MatHeaderCellDef,
    NgClass,
    InteractiveMapComponent,
    CustomButtonComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuContent,
    NgOptimizedImage,
    InfiniteScrollDirective,
    TranslatePipe,
    LanguageSelectorComponent,
    RaceSelectorComponent,
    TitleCasePipe,
    PartnerBotChatComponent,
    MatTabGroup,
    MatTab,
  ],
  templateUrl: './ai-chat.component.html',
  styleUrl: './ai-chat.component.scss',
})
export class AiChatComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @ViewChild('partnerChat') partnerChat!: ElementRef;
  @ViewChild('inputElement') inputElement!: ElementRef;
  @ViewChild(PartnerBotChatComponent)
  partnerBotChatComponent?: PartnerBotChatComponent;
  userMessage: string = '';
  prompts: string[] = [...ADDITIONAL_PROMPTS_LIST, ...WIDGET_PROMPTS_LIST];
  raceId: string = '';
  isChatLoading: boolean = false;
  messages: MappedChatMessage[] = [];
  partnerBotMessages: MappedChatMessage[] = [];
  elevationsData: TrackCoordinates[] | null = [];
  race: Race | null = null;
  start_point: Coordinates | null = null;
  race_units: string = '';
  radius_center_point: Coordinates | null = null;
  displayedColumns: string[] = [
    'month',
    'runs_count',
    'total_distance',
    'longest_run',
  ];
  newDataSource: MatTableDataSource<{ [key: string]: string }>;
  isMessageLoading: boolean = false;
  segment_tracks: SegmentTracks[] | null = [];
  path: Coordinates[][] | null = null;
  isChatHistoryEmpty: boolean = false;
  AIRequestError: boolean = false;
  segment_coordinates: {
    startPointIndex: number;
    endPointIndex: number;
  } | null = null;
  race_radius: number = 0;
  units: string = 'imperial';
  segment_distance: number = 0;
  prompt: string = '';
  voiceMode: boolean = false;
  hasRunkeeperTokenProcessed = false;
  races: Race[] = [];
  isSpeechProcessing: boolean = false;
  isRecording: boolean = false;
  speechStartTime: number = 0;
  deviceStream: MediaStream | null = null;
  speechQueue: { chunk: Float32Array; time: number }[] = [];
  speechQueueProcessingInterval: number = 0;
  sttTimeout: number = 1.0;
  submitSpeechTimeout: number = 0;
  audioContext: AudioContext | null = null;
  audioWorkletNode: AudioWorkletNode | null = null;
  micStream: MediaStreamAudioSourceNode | null = null;
  isMicSelectorOpened: boolean = false;
  audioDevices: MediaDeviceInfo[] = [];
  selectedDeviceId: string | null = null;
  raceTypes: RaceTypes[] = [];
  currentRace: Race | null = null;
  isMobile: boolean = false;
  access_token: string = '';
  refresh_token: string = '';
  mainColor: string = '';
  isChatHistoryLoading: boolean = false;
  isAtBottom: boolean = true;
  showLoadMore: boolean = false;
  initialLoad: boolean = true;
  closeLogo: SafeUrl = WIDGET_CLOSE_LOGO;
  closeSvgTemplate: string = WIDGET_CLOSE_SVG;
  arrowForwardLogo: SafeUrl = WIDGET_ARROW_FORWARD_LOGO;
  arrowForwardSvgTemplate: string = WIDGET_ARROW_FORWARD_SVG;
  accountLogo: SafeUrl = WIDGET_ACCOUNT_LOGO;
  accountSvgTemplate: string = WIDGET_ACCOUNT_SVG;
  language: string = 'en';
  showPartnerChat: boolean = false;
  selectedPartner: PartnerBots | null = null;
  partnerBotInfo: PartnerBotInfo | null = null;
  partnerBotId: string = '';
  mapConfig: {
    widget?: string;
    gpx: GpxFile;
    center_coordinate?: TrackCoordinates | undefined;
  } | null = null;
  selectedTabIndex: number = 0;
  conversation_guid: string | null = null;
  user_guid: string | null = null;
  isPromptsLoading: boolean = false;
  subscriptions: Subscription[] = [];
  isUserOnline: boolean = true;
  pendingMessage: string | null = null;
  isCaptchaCompleted: boolean = false;
  isCaptchaError: boolean = false;
  isCaptchaLoading: boolean = false;
  session_user_guid: string | null = null;
  conversationScenario: CONVERSATION_SCENARIO =
    CONVERSATION_SCENARIO.EVENT_DETAILS;
  shouldRenderMap: boolean = true;
  isUserLoggedIn: boolean = false;
  showLogin: boolean = false;
  keycloakToken: string | null = null;
  isRaceSelected: boolean = false;

  private boundOnRunkeeperToken: (event: StorageEvent) => void;
  private loadingMessageTimeout: any = null;
  private scrollListener: any;
  private messageSubscription!: Subscription;
  private reconnectionSubscription!: Subscription;
  private onConnectionReadySubscription!: Subscription;
  private connectionReadySubscription!: Subscription | null;
  private hasSubscribedMessages = false;

  constructor(
    private keycloakService: KeycloakService,
    private chatService: ChatService,
    private sanitizer: DomSanitizer,
    private raceService: RaceService,
    private readonly oidcService: OidcServiceService,
    private googleMapsService: GoogleMapsServiceService,
    private screenSizeService: ScreenResizeService,
    private userService: UserService,
    private svgLogoService: SvgLogoService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private fitnessAccountService: FitnessAccountSocketService,
  ) {
    this.newDataSource = new MatTableDataSource<{ [key: string]: string }>([]);
    this.boundOnRunkeeperToken = this.onRunkeeperToken.bind(this);
  }

  ngOnInit() {
    const mainColor = localStorage.getItem(MAIN_COLOR);

    if (mainColor) {
      this.mainColor = mainColor;
      this.updateCloseIconColor(this.mainColor);
      this.updateArrowForwardIconColor(this.mainColor);
      this.updateAccountIconColor(this.mainColor);
      localStorage.removeItem(MAIN_COLOR);
    }

    window.addEventListener('message', (event) => {
      if (event.data.type === 'mainColor') {
        const receivedData = event.data.payload;
        localStorage.setItem(MAIN_COLOR, receivedData);
        this.mainColor = receivedData;
        this.updateCloseIconColor(this.mainColor);
        this.updateAccountIconColor(this.mainColor);
        this.updateArrowForwardIconColor(this.mainColor);
      }
    });

    const root = document.documentElement;
    root.style.setProperty('--main-color', this.mainColor || '#6271FF');
  }

  async initChat() {
    const savedPrompt = localStorage.getItem('prompt');
    const keycloakToken = localStorage.getItem('keycloak-token');
    const selectedRaceId = localStorage.getItem(SELECTED_RACE_ID);
    const eventRaces = localStorage.getItem(EVENT_RACES);
    const raceTypes = localStorage.getItem(RACE_TYPES);
    const voiceMode = localStorage.getItem('voiceMode');
    const parsedVoiceMode = voiceMode ? JSON.parse(voiceMode) : null;
    const parsedRaces = eventRaces ? JSON.parse(eventRaces) : null;
    const parsedRaceTypes = raceTypes ? JSON.parse(raceTypes) : null;
    const language = localStorage.getItem('language');

    if (keycloakToken) {
      this.keycloakToken = keycloakToken;
      this.access_token = keycloakToken;
    }

    this.checkConnectionStatus();

    this.reconnectionSubscription = this.chatService
      .getReconnectionObservable()
      .subscribe(() => {
        const message = this.showPartnerChat
          ? this.partnerBotMessages[this.partnerBotMessages?.length - 1]
          : this.messages[this.messages.length - 1];

        this.pendingMessage = message?.text.toString();

        if (message?.is_system) return;

        setTimeout(() => {
          if (!this.conversation_guid) return;
          this.chatService.sendChangeScenarioMessage(
            AI_REQUESTS[0],
            this.conversation_guid,
          );
          this.scrollToBottom();
          this.addLoadingMessage();
          setTimeout(() => {
            if (!this.conversation_guid || !this.pendingMessage) return;
            this.chatService.sendMessage(
              this.pendingMessage,
              this.conversation_guid,
            );
            this.userMessage = '';
            this.pendingMessage = '';
          }, 400);
        }, 1000);
      });

    this.onConnectionReadySubscription = this.chatService
      .getOnConnectionObservable()
      .subscribe((data: OnConnectionReadyResponse) => {
        this.inputElement?.nativeElement?.focus();
        if (data) {
          if (this.showPartnerChat) return;
          localStorage.setItem(CONVERSATION_GUID, data?.conversation_guid);
          this.conversation_guid = data?.conversation_guid;
          this.user_guid = data?.user_guid;
        }
      });

    this.screenSizeService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    if (savedPrompt) {
      this.prompt = savedPrompt;
      localStorage.removeItem('prompt');
    }

    if (selectedRaceId) {
      this.raceId = selectedRaceId;
      localStorage.removeItem(SELECTED_RACE_ID);
      window.parent.postMessage(
        {
          type: 'analytics:setRaceGuid',
          payload: this.raceId,
        },
        '*',
      );
    }

    if (parsedRaces) {
      this.races = parsedRaces;
      localStorage.removeItem(EVENT_RACES);
    }

    if (parsedRaceTypes) {
      this.raceTypes = parsedRaceTypes;
      localStorage.removeItem(RACE_TYPES);
    }

    if (parsedVoiceMode) {
      this.races = parsedVoiceMode;
      localStorage.removeItem('voiceMode');
    }

    if (language) {
      this.language = language;
      this.translate.use(language);
    }

    window.addEventListener('storage', this.boundOnRunkeeperToken);
    window.addEventListener('message', (event) => {
      if (event.data.type === 'prompt') {
        const receivedData = event.data.payload;
        localStorage.setItem('prompt', receivedData);
        this.prompt = receivedData;
      }

      if (event.data.type === 'raceId') {
        const receivedData = event.data.payload;
        localStorage.setItem(SELECTED_RACE_ID, receivedData);
        this.raceId = receivedData;
      }

      if (event.data.type === 'voiceMode') {
        const receivedData = event.data.payload;
        localStorage.setItem('voiceMode', JSON.stringify(receivedData));
        this.voiceMode = receivedData;
      }

      if (event.data.type === 'races') {
        const receivedData = event.data.payload;
        localStorage.setItem(EVENT_RACES, JSON.stringify(receivedData));
        this.races = receivedData;
      }

      if (event.data.type === 'raceTypes') {
        const receivedData = event.data.payload;
        localStorage.setItem(RACE_TYPES, JSON.stringify(receivedData));
        this.raceTypes = receivedData;
        this.getCurrentRace();
      }

      if (event.data.type === 'language') {
        const receivedData = event.data.payload;
        localStorage.setItem(SELECTED_LANGUAGE, receivedData);
        this.language = receivedData;
      }

      if (event.data.type === 'keycloak-token') {
        localStorage.setItem('keycloak-token', event.data.token);
        this.keycloakToken = event.data.token;
        window.location.reload();
      }

      if (
        event.data.type === 'runkeeper_token' ||
        event.data.type === 'strava_token' ||
        event.data.type === 'garmin_token'
      ) {
        if (this.hasRunkeeperTokenProcessed) return;
        if (event.data?.token) {
          const accountType = event.data.type.replace('_token', '');
          this.chatService.sendLinkAccountMessage(
            event.data.token,
            accountType,
            event.data?.token_secret,
          );
          this.hasRunkeeperTokenProcessed = true;
        }
      }
    });

    window.parent.postMessage(
      { type: 'iframeReady', payload: this.races },
      '*',
    );

    this.checkUserAuth();

    try {
      await this.getAudioDevices();
      const deviceId = localStorage.getItem('microphone-device-id');
      if (this.audioDevices?.find((d) => d.deviceId === deviceId)) {
        this.selectedDeviceId = deviceId;
      } else {
        this.selectedDeviceId = this.audioDevices
          ? this.audioDevices[0]?.deviceId
          : '';
      }
    } catch (error) {
      console.log('MICROPHONE ERROR', error);
    }
  }

  async getCurrentRace() {
    if (this.raceTypes.length > 0) {
      const selectedRace = this.raceTypes?.find((d) => d.id === this.raceId);
      if (selectedRace?.is_default) {
        this.currentRace = null;
        this.isRaceSelected = false;
        this.sendNotSelectedRaceMessages();
        return;
      }
    }
    if (this.races?.length) {
      const currentRace = this.races?.find((i) => i.guid === this.raceId);
      if (currentRace) {
        this.currentRace = currentRace;
        this.race_units = currentRace.units;
        this.isRaceSelected = true;
      }
    }
  }

  sendNotSelectedRaceMessages() {
    if (!this.isRaceSelected) {
      this.messages.push({
        text: this.prompt,
        is_system: false,
        sent_at: new Date(),
      });
      this.scrollToBottom();

      const races = this.raceTypes
        .filter((i) => !i.is_default)
        .map((i) => i.name);

      this.messages.push({
        text: this.translate.instant(
          'To give you the most accurate information, let me know which race you’re interested in:',
        ),
        is_system: true,
        sent_at: new Date(),
      });
      this.messages.push({
        text: '',
        is_system: false,
        sent_at: new Date(),
        is_options_message: true,
        race_options: true,
        options: races,
      });
      this.scrollToBottom();
    }
  }

  selectRace(id: string) {
    if (id) {
      this.isRaceSelected = id !== '1';
      this.raceId = id;
      this.refreshMap();
      window.parent.postMessage(
        {
          type: 'analytics:setRaceGuid',
          payload: this.raceId,
        },
        '*',
      );
      window.removeEventListener('storage', this.boundOnRunkeeperToken);
      this.chatService.disconnect();
      this.messages = [];
      this.connectionReadySubscription = null;
      if (this.isRaceSelected) {
        this.getCurrentRace();
        if (this.access_token) {
          this.isChatLoading = true;
          this.loadRaceData();
          localStorage.setItem(SELECTED_RACE_ID, this.raceId);
          window.parent.postMessage(
            { type: 'raceId', payload: this.raceId },
            '*',
          );
        }
      } else {
        this.sendNotSelectedRaceMessages();
        window.parent.postMessage({ type: 'raceId', payload: '1' }, '*');
      }
    }
  }

  updateCloseIconColor(color: string) {
    this.closeLogo = this.svgLogoService.updateSvgLogo(
      this.closeSvgTemplate,
      color,
      '#6271FF',
    );
  }

  updateArrowForwardIconColor(color: string) {
    this.arrowForwardLogo = this.svgLogoService.updateSvgLogo(
      this.arrowForwardSvgTemplate,
      color,
      '#6271FF',
    );
  }

  updateAccountIconColor(color: string) {
    this.accountLogo = this.svgLogoService.updateSvgLogo(
      this.accountSvgTemplate,
      color,
      '#4C57BC',
    );
  }

  async ngAfterViewInit() {
    if (window.turnstile && typeof window.turnstile.ready === 'function') {
      if (environment.development) {
        this.isCaptchaCompleted = true;
        this.isCaptchaError = false;
        this.isCaptchaLoading = false;
        this.initChat();
      } else {
        window.turnstile.ready(() => {
          const container = document.getElementById('example-container');
          this.isCaptchaLoading = true;
          if (container) {
            window.turnstile.render(container, {
              sitekey: environment.cloudflareTurnstileSiteKey,
              callback: () => {
                console.log(`Challenge Success`);
                this.isCaptchaCompleted = true;
                this.isCaptchaError = false;
                this.isCaptchaLoading = false;
                this.initChat();
              },
              'expired-callback': () => {
                console.log('Turnstile token expired.');
                this.isCaptchaLoading = false;
              },
              'error-callback': () => {
                console.error('Turnstile challenge failed.');
                this.isCaptchaCompleted = false;
                this.isCaptchaError = true;
                this.isCaptchaLoading = false;
              },
            });
          } else {
            console.error('Turnstile container not found.');
            this.isCaptchaLoading = false;
          }
        });
      }
    } else {
      console.error('Cloudflare Turnstile API not loaded.');
      this.isCaptchaLoading = false;
    }

    this.speechQueueProcessingInterval = setAsyncInterval(async () => {
      if (this.speechQueue.length && !this.isSpeechProcessing) {
        this.isSpeechProcessing = true;

        this.speechQueue.shift();

        const currentTime = new Date().getTime();
        if (
          this.speechStartTime &&
          (currentTime - this.speechStartTime) / 1000 > this.sttTimeout
        ) {
          this.speechStartTime = 0;
          if (this.submitSpeechTimeout) {
            clearTimeout(this.submitSpeechTimeout);
          }
          this.stopMicRecording();
        }

        this.isSpeechProcessing = false;
      }
    }, 1);

    this.scrollListener = this.onScroll.bind(this);
    this.chatContainer?.nativeElement?.addEventListener(
      'scroll',
      this.scrollListener,
    );
  }

  async checkUserAuth() {
    if (this.keycloakToken) {
      const isLoggedIn = this.keycloakService.isLoggedIn();
      if (!isLoggedIn) {
        this.keycloakToken = null;
      } else if (isLoggedIn) {
        const isExpired = this.keycloakService.isTokenExpired();
        let token = await this.keycloakService.getToken();
        if (isExpired) {
          this.keycloakService.updateToken();
          token = await this.keycloakService.getToken();
        }
        this.access_token = token;
        await this.getCurrentRace();
        this.loadRaceData();
        return;
      }
    }
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    const sessionUserGuid = localStorage.getItem(SESSION_USER_GUID);

    if (!accessToken || !refreshToken) {
      this.initAnonymousUser();
    } else {
      this.access_token = accessToken;
      this.refresh_token = refreshToken;
      this.session_user_guid = sessionUserGuid;
      this.postAccessTokenMessage(this.access_token);

      if (!sessionUserGuid) {
        this.initAnonymousUser();
      } else {
        this.userService
          .introspectToken(this.access_token)
          .subscribe((data) => {
            if (!data) return;

            if (!data?.active) {
              this.userService.refreshToken(this.refresh_token).subscribe(
                (response) => {
                  if (response) {
                    localStorage.setItem(ACCESS_TOKEN, response.access_token);
                    localStorage.setItem(REFRESH_TOKEN, response.refresh_token);

                    this.access_token = response.access_token;
                    this.refresh_token = response.refresh_token;

                    this.postAccessTokenMessage(this.access_token);
                    window.location.reload();
                    return;
                  }
                },
                () => {
                  localStorage.removeItem(ACCESS_TOKEN);
                  localStorage.removeItem(REFRESH_TOKEN);
                  window.location.reload();
                  return;
                },
              );
              return;
            }

            if (this.isRaceSelected) {
              this.loadRaceData();
              this.getCurrentRace();
            }
          });
      }
    }
  }

  checkIfTokenExpired() {
    if (!this.access_token) return true;
    try {
      const decoded: { exp: number } = jwtDecode(this.access_token);
      console.log('decoded', decoded);
      const currentTime = Math.floor(Date.now() / 1000);
      const isTokenExpired = decoded.exp < currentTime;

      if (isTokenExpired) {
        localStorage.removeItem('keycloak-token');
        this.access_token = '';
      }

      return isTokenExpired;
    } catch (error) {
      console.error('Invalid token', error);
      return true;
    }
  }

  keycloakLogin() {
    const loginUrl = this.keycloakService.getKeycloakInstance().createLoginUrl({
      redirectUri: `${window.location.origin}/keycloak-auth-callback`,
    });
    const popup = window.open(
      loginUrl,
      '_blank',
      'width=600,height=800,scrollbars=yes',
    );

    if (!popup) {
      alert(
        'Popup blocked! Please allow popups for this site or click the Login button.',
      );
    } else {
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
        }
      }, 500);
    }
  }

  keycloakRegister() {
    const loginUrl = this.keycloakService
      .getKeycloakInstance()
      .createRegisterUrl({
        redirectUri: `${window.location.origin}/keycloak-auth-callback`,
      });
    const popup = window.open(
      loginUrl,
      '_blank',
      'width=600,height=800,scrollbars=yes',
    );

    if (!popup) {
      alert(
        'Popup blocked! Please allow popups for this site or click the register button.',
      );
    } else {
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
        }
      }, 500);
    }
  }

  initAnonymousUser() {
    this.userService.initAnonymousUser().subscribe((data) => {
      if (!data) return;

      this.access_token = data?.access_token;
      this.refresh_token = data?.refresh_token;
      this.session_user_guid = data?.session_user_guid;

      localStorage.setItem(ACCESS_TOKEN, this.access_token);
      localStorage.setItem(REFRESH_TOKEN, this.refresh_token);
      localStorage.setItem(SESSION_USER_GUID, this.session_user_guid);

      this.postAccessTokenMessage(this.access_token);

      if (this.isRaceSelected) {
        this.loadRaceData();
        this.getCurrentRace();
      }
    });
  }

  async getAudioDevices(): Promise<void> {
    try {
      const devices = await navigator.mediaDevices?.enumerateDevices();
      this.audioDevices = devices?.filter(
        (device) => device?.kind === 'audioinput',
      );
    } catch (error) {
      console.error('Error fetching audio devices:', error);
    }
  }

  selectDevice(deviceId: string | null): void {
    if (!deviceId) return;
    this.selectedDeviceId = deviceId;
    localStorage.setItem('microphone-device-id', deviceId);
  }

  async setupMicStream() {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
      await this.audioContext.audioWorklet.addModule(
        '/assets/js/audio-processor.js',
      );
    }

    this.deviceStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: this.selectedDeviceId
        ? {
            deviceId: this.selectedDeviceId,
          }
        : true,
    });

    this.audioWorkletNode = new AudioWorkletNode(
      this.audioContext,
      'audio-processor',
    );
    this.audioWorkletNode.port.onmessage = (event) => {
      const raw = event.data; // Отримання Float32Array
      if (raw === null) return;
      const downSampledBuffer = downSampleBuffer(raw, undefined, 16000);
      const pcmEncodedBuffer = pcmEncode(downSampledBuffer);
      this.chatService.sendGoogleSttData(pcmEncodedBuffer);
    };
    this.micStream = this.audioContext.createMediaStreamSource(
      this.deviceStream,
    );
    this.micStream.connect(this.audioWorkletNode);
    this.audioWorkletNode.connect(this.audioContext.destination);
  }

  private showMicDeniedDialog() {
    this.dialog.open(DialogComponent, {
      width: '300px',
      data: {
        headerTitle: 'Permissions request',
        contentText:
          'Please grant access to the microphone first, to use voice input',
        submitBtnText: 'Request again',
        onCancel: () => {},
      },
    });
  }

  async startMicRecording() {
    let isAccessibleMic = false;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      isAccessibleMic = true;
    } catch (error) {
      isAccessibleMic = false;
    }
    if (!isAccessibleMic) {
      return this.showMicDeniedDialog();
    }

    this.voiceMode = true;
    this.isRecording = true;
    this.speechStartTime = 0;
    this.chatService.startGoogleStt();
    await this.setupMicStream();
  }

  stopMicRecording() {
    this.chatService.stopGoogleStt();
    this.deviceStream?.getAudioTracks().forEach((track) => {
      track.stop();
    });
    if (this.audioWorkletNode) {
      this.audioWorkletNode.disconnect();
    }
    if (this.micStream) {
      this.micStream.disconnect();
    }
    if (this.audioContext) {
      this.audioContext.close().then(() => {
        this.audioContext = null;
      });
    }
    this.isRecording = false;
    this.voiceMode = false;
  }

  cancelVoiceMode() {
    if (this.isRecording) {
      this.stopMicRecording();
    }
    this.voiceMode = false;
  }

  onUp() {
    if (this.isChatHistoryEmpty || !this.conversation_guid) return;
    const lastMessageTimestamp = this.showPartnerChat
      ? this.partnerBotMessages[0].sent_at
      : this.messages[0]?.sent_at;
    this.isChatHistoryLoading = true;

    this.chatService.requestChatHistory(
      20,
      lastMessageTimestamp,
      this.conversation_guid,
    );
  }

  onRunkeeperToken(event: StorageEvent) {
    if (this.hasRunkeeperTokenProcessed) return;
    if (event.storageArea !== localStorage) return;
    if (
      (event.key === 'runkeeper_token' ||
        event.key === 'strava_token' ||
        event.key === 'garmin_token') &&
      event.newValue
    ) {
      const runkeeperData = JSON.parse(event.newValue);
      if (runkeeperData.token) {
        const accountType = event.key.replace('_token', '');
        this.chatService.sendLinkAccountMessage(
          runkeeperData.token,
          accountType,
          runkeeperData?.token_secret,
        );
        this.hasRunkeeperTokenProcessed = true;
      }
      window.removeEventListener('storage', this.onRunkeeperToken);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.boundOnRunkeeperToken);
    clearAsyncInterval(this.speechQueueProcessingInterval);
    this.chatService.disconnect();

    if (this.chatContainer && this.scrollListener) {
      this.chatContainer?.nativeElement?.removeEventListener(
        'scroll',
        this.scrollListener,
      );

      this.partnerChat?.nativeElement?.removeEventListener(
        'scroll',
        this.scrollListener,
      );
    }

    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    if (this.connectionReadySubscription) {
      this.connectionReadySubscription.unsubscribe();
    }

    if (this.reconnectionSubscription) {
      this.reconnectionSubscription.unsubscribe();
    }

    if (this.onConnectionReadySubscription) {
      this.onConnectionReadySubscription.unsubscribe();
    }
  }

  onScroll(): void {
    const element = this.getScrollChatElement();
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const clientHeight = element.clientHeight;

    this.isAtBottom = scrollTop + clientHeight >= scrollHeight - 150;

    if (scrollTop < 50 && !this.isChatHistoryEmpty) {
      if (this.initialLoad) {
        this.showLoadMore = true;
      } else {
        if (!this.isChatHistoryLoading) {
          this.onUp();
        }
      }
    } else {
      this.showLoadMore = false;
    }
  }

  private loadRaceData() {
    if (this.raceId && this.isRaceSelected) {
      this.raceService.getRaceById(this.raceId).subscribe({
        next: (response) => {
          this.race = response
            ? { ...response, starting_at: response?.starting_at.slice(0, -1) }
            : null;
          if (this.race?.guid) {
            this.getGpxData(this.race?.guid);
          }
          this.initializeChat(this.access_token);
        },
      });
    }
  }

  private getUserSettings() {
    if (!this.access_token) return;
    this.userService
      .getUserSettings(this.access_token)
      .subscribe((response) => {
        if (response) {
          this.language = response?.language;

          window.parent.postMessage(
            { type: 'userLanguage', payload: this.language },
            '*',
          );

          localStorage.setItem(SELECTED_LANGUAGE, this.language);
        }
      });
  }

  private updateUserLanguage(language: string) {
    if (!this.access_token) return;
    this.userService
      .updateUserSettings({ settings: { language } }, this.access_token)
      .subscribe((response) => {
        console.log(response);
      });
  }

  getGpxData(guid: string) {
    this.raceService.getGpxData(guid).subscribe((response) => {
      if (response?.start_point && response?.end_point) {
        if (response?.start_point.lat && response?.start_point.lon) {
          this.start_point = {
            lat: response.start_point.lat,
            lng: response.start_point.lon,
          };
        }
        this.mapConfig = {
          gpx: response,
        };

        this.elevationsData = response.tracks[0].geometry.coordinates;
      }
    });
  }

  private async initializeChat(token: string) {
    this.chatService
      .init(this.raceId, token, this.session_user_guid)
      .then(() => {
        if (!this.connectionReadySubscription) {
          this.isChatLoading = true;
          this.connectionReadySubscription = this.chatService
            .onConnectionReady()
            .subscribe((data) => {
              if (data) {
                localStorage.setItem(
                  CONVERSATION_GUID,
                  data?.conversation_guid,
                );
                this.conversation_guid = data?.conversation_guid;
                this.user_guid = data?.user_guid;
              }
              if (!this.hasSubscribedMessages) {
                this.isChatLoading = false;
                this.loadChatMessages();
                this.hasSubscribedMessages = true;
              }
              setTimeout(() => {
                this.chatService.sendUpdateUserLanguage(this.language);
              }, 300);
              this.isChatLoading = false;
              this.loadChatHistory();
              this.subscribeToScenarioChange();
              this.subscribeToSttMessage();
              this.subscribeToRawSttMessage();
              this.getPartnerBotChatInfo();
            });
        }
      });
  }

  private loadChatHistory() {
    if (this.prompt !== null) {
      this.chatService.getChatHistory().subscribe((data) => {
        this.processChatHistory(data);
      });
    }
  }

  private subscribeToScenarioChange() {
    if (this.prompt !== null) {
      this.chatService
        .getConversationScenario()
        .subscribe((message: ScenarioChangeMessage) => {
          this.handleConversationScenario(message);
        });
    }
  }

  private getPartnerBotChatInfo() {
    this.chatService.onSwitchPartnerBot().subscribe((data) => {
      this.isPromptsLoading = false;
      this.isChatLoading = false;
      this.isChatHistoryLoading = false;
      const partnerBotsInfo = data?.partner_bot_info;
      this.conversation_guid = data?.conversation_guid;
      this.partnerBotMessages.push({
        sent_at: new Date(),
        text: partnerBotsInfo.description,
        is_system: true,
      });
      this.partnerBotInfo = partnerBotsInfo;
      this.prompts = partnerBotsInfo?.questions || PARTNER_PROMPTS_LIST;

      this.chatService.requestChatHistory(
        20,
        new Date(),
        this.conversation_guid,
      );
    });
  }

  private loadChatMessages() {
    this.messageSubscription = this.chatService
      .getMessagesStream()
      .subscribe((message: ChatMessage) => {
        this.processIncomingMessage(message);
      });
  }

  private subscribeToSttMessage() {
    this.chatService.getSttMessage().subscribe((message: SttMessage) => {
      this.userMessage = message.message;
      this.sendMessage();
      this.stopMicRecording();
    });
  }

  private subscribeToRawSttMessage() {
    this.chatService.getRawSttMessage().subscribe((message: RawSttMessage) => {
      const alternatives = message.alternatives[0];
      this.userMessage = alternatives?.transcript;
    });
  }

  private handleConversationScenario({ scenario }: ScenarioChangeMessage) {
    this.conversationScenario = scenario;
  }

  private processChatHistory(data: {
    messages: ChatMessage[];
    take: number;
    from: number;
    total_count: number;
  }) {
    const messageHistoryLength = this.showPartnerChat
      ? this.partnerBotMessages?.length - 1
      : this.messages.length;
    if (data?.messages?.length) {
      this.isChatHistoryLoading = false;
      this.isChatHistoryEmpty = false;
      const formattedMessages = this.formatChatHistory(data.messages);
      if (this.showPartnerChat) {
        this.partnerBotMessages.unshift(...formattedMessages);
      } else {
        this.messages.unshift(...formattedMessages);
      }
    } else {
      this.isChatHistoryEmpty = true;
      this.showLoadMore = false;
      this.isChatHistoryLoading = false;
    }

    if (!messageHistoryLength) {
      if (!this.showPartnerChat) {
        this.sendInitialMessage();
      }

      this.scrollToBottom();
    }
  }

  private sendInitialMessage() {
    if (!this.prompt) {
      this.scrollToBottom();
      return;
    }
    if (
      this.prompt &&
      ![...WIDGET_PROMPTS_LIST, ...ADDITIONAL_PROMPTS_LIST].includes(
        this.prompt,
      )
    ) {
      if (!this.conversation_guid) return;
      this.chatService.sendChangeScenarioMessage(
        AI_REQUESTS[0],
        this.conversation_guid,
      );
      this.messages.push({
        text: this.prompt,
        is_system: false,
        sent_at: new Date(),
      });
      this.scrollToBottom();
      this.addLoadingMessage();
      const translatedMessage = ADDITIONAL_PROMPTS_LIST.includes(this.prompt)
        ? this.translate.instant(this.prompt)
        : this.prompt;
      setTimeout(() => {
        if (!this.conversation_guid) return;
        this.chatService.sendMessage(translatedMessage, this.conversation_guid);
        this.userMessage = '';
      }, 100);
    } else {
      if (this.prompt && this.conversation_guid) {
        if (ADDITIONAL_PROMPTS_LIST.includes(this.prompt)) {
          this.chatService.sendChangeScenarioMessage(
            AI_REQUESTS[0],
            this.conversation_guid,
          );
        }
        setTimeout(() => {
          if (!this.conversation_guid) return;
          const prompt = ADDITIONAL_PROMPTS_LIST.includes(this.prompt)
            ? this.translate.instant(this.prompt)
            : this.prompt;
          this.chatService.sendMessage(prompt, this.conversation_guid);
        }, 300);
        const translatedMessage = this.translate.instant(this.prompt);
        this.messages.push({
          text: translatedMessage,
          is_system: false,
          sent_at: new Date(),
        });
        this.addLoadingMessage();
      }
      this.scrollToBottom();
    }
  }

  private addLoadingMessage() {
    this.isMessageLoading = true;
    this.pushMessage({
      text: '',
      is_system: true,
      loading: true,
      sent_at: new Date(),
    });

    this.loadingMessageTimeout = setTimeout(() => {
      this.removeLoadingMessage();
      this.AIRequestError = true;
    }, AI_REQUEST_TIMEOUT);
  }

  private removeLoadingMessage() {
    if (this.showPartnerChat) {
      const lastMessage = this.partnerBotMessages.find(
        (msg) => msg.loading && msg.is_system,
      );
      if (lastMessage) {
        this.partnerBotMessages = this.partnerBotMessages.filter(
          (msg) => msg !== lastMessage,
        );
      }
    } else {
      const lastMessage = this.messages.find(
        (msg) => msg.loading && msg.is_system,
      );
      if (lastMessage) {
        this.messages = this.messages.filter((msg) => msg !== lastMessage);
      }
    }

    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }
  }

  private processIncomingMessage(message: ChatMessage) {
    this.isChatLoading = false;
    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }

    let lastMessage: MappedChatMessage | undefined;
    if (this.showPartnerChat) {
      lastMessage = this.partnerBotMessages.find(
        (msg) => msg.loading && msg.is_system,
      );
    } else {
      lastMessage = this.messages.find((msg) => msg.loading && msg.is_system);
    }

    if (lastMessage) {
      this.updateLastMessage(lastMessage, message);
    } else {
      this.addNewMessage(message);
    }

    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  scrollToBottom(behavior: string = 'smooth'): void {
    if (this.showPartnerChat && this.partnerBotChatComponent) {
      this.partnerBotChatComponent.scrollToBottom();
    } else if (behavior === 'immediate') {
      const scrollElement = this.getScrollChatElement();
      scrollElement.scrollTop = scrollElement.scrollHeight;
      setTimeout(() => {
        const scrollElement = this.getScrollChatElement();
        scrollElement?.scrollTo({
          top: scrollElement?.scrollHeight,
          behavior: 'smooth',
        });
      }, 300);
    } else {
      setTimeout(() => {
        const scrollElement = this.getScrollChatElement();

        scrollElement?.scrollTo({
          top: scrollElement?.scrollHeight,
          behavior,
        });
      }, 300);
    }
  }

  private formatChatHistory(data: ChatMessage[]) {
    return data.flatMap((i) => {
      const baseMessage = {
        text: this.formatMessage(i.data?.message, i?.data?.links || []) || '',
        is_system: i.is_system,
        loading: false,
        sent_at: new Date(i.sent_at),
      };

      const gear = i?.data?.gears?.map((gear) => {
        const gearLinkText = {
          text: `${this.translate.instant('Explore')} ${gear.brand}`,
          link: gear?.link,
        };
        const gearLabel = gear.name;

        return {
          text: gearLabel,
          link: gearLinkText,
          image: gear?.images ? gear?.images[0]?.file_url : '',
        };
      });

      const gearMessage = [
        {
          text: '',
          label: '',
          sent_at: new Date(i.sent_at),
          gearOptions: gear,
          is_system: true,
        },
      ];
      return gearMessage ? [baseMessage, ...gearMessage] : baseMessage;
    });
  }

  private addNewMessage(message: ChatMessage) {
    this.pushMessage({
      text: this.formatMessage(
        message.data?.message,
        message?.data?.links || [],
      ),
      is_system: true,
      sent_at: new Date(),
    });

    if (message?.data?.options) {
      this.pushMessage({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      });
    }
    if (message?.data?.widgets) {
      let options: string[] = [];

      const mapWidgets = [
        INTERACTIVE_WIDGETS.MAP_START_POINT,
        INTERACTIVE_WIDGETS.MAP_FINISH_POINT,
        INTERACTIVE_WIDGETS.MAP_ROUTE,
        INTERACTIVE_WIDGETS.AID_STATIONS,
        INTERACTIVE_WIDGETS.MAP_POINTS_OF_INTEREST,
        INTERACTIVE_WIDGETS.MILE_POINT,
      ];
      if (mapWidgets.includes(message.data.widgets[0] as INTERACTIVE_WIDGETS)) {
        if (message.data?.gpx) {
          this.mapConfig = {
            gpx: message?.data?.gpx,
            widget: message.data.widgets[0],
            center_coordinate: message.data?.center_coordinate,
          };

          this.disableLastMapMessage();

          this.pushMessage({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_map: true,
            gpx: message?.data?.gpx,
            widget: message.data.widgets[0],
            center_coordinate: message.data?.center_coordinate,
            options: message.data?.options || [],
          });
        }
      }

      const elevationWidgets = [INTERACTIVE_WIDGETS.ELEVATION_PROFILE];
      if (
        elevationWidgets.includes(
          message.data.widgets[0] as INTERACTIVE_WIDGETS,
        )
      ) {
        if (message.data?.gpx) {
          this.pushMessage({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_elevation: true,
            gpx: message?.data?.gpx,
            widget: message.data.widgets[0],
            center_coordinate: message.data?.center_coordinate,
            options: message.data?.options || [],
          });
        }
      }

      if (message.data.widgets[0] === 'MAP_SEGMENT') {
        if (message.data?.gpx) {
          this.disableLastMapMessage();
          this.path = message?.data?.gpx.tracks.map((i) =>
            this.getMappedPath(i),
          );
          this.pushMessage({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_map: true,
            options: message.data?.options || [],
          });
        }
      }

      if (message.data.widgets[0] === 'MAP_SEGMENT') {
        if (message.data?.gpx) {
          this.disableLastMapMessage();
          this.path = message?.data?.gpx.tracks.map((i) =>
            this.getMappedPath(i),
          );
          this.pushMessage({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_map: true,
            options: message.data?.options || [],
          });
        }
      }

      if (message?.data?.widgets[0] === 'SEGMENT_PICKER') {
        options = [CHOOSE_SEGMENT_OPTION];
      }

      if (
        message?.data?.widgets[0] === 'RADIUS_PICKER' &&
        !this.radius_center_point
      ) {
        if (this.radius_center_point) {
          options = [SET_RADIUS_OPTION];
        } else {
          options = [SHOW_MAP_OPTION];
        }
      }

      if (
        options?.length &&
        message?.data?.widgets[0] === 'RADIUS_PICKER' &&
        options[0] !== SET_RADIUS_OPTION
      ) {
        this.sendWidgetOption(options);
      }

      this.pushMessage({
        text: '',
        is_system: true,
        sent_at: new Date(),
        widget: message?.data?.widgets[0],
      });

      if (
        (options?.length && message?.data?.widgets[0] === 'SEGMENT_PICKER') ||
        options[0] === SET_RADIUS_OPTION
      ) {
        this.sendWidgetOption(options);
      }
    }

    if (message?.data?.table?.length) {
      const table = this.convertTableToElementData(message?.data?.table);
      this.pushMessage({
        text: '',
        is_options_message: true,
        is_system: true,
        sent_at: new Date(),
        table: table,
      });
      this.addLoadingMessage();
    }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
    this.choicePartnerBotMessage(message);
    this.addPartnerBotInfo(message);
    this.partnerWebsiteLink(message);
  }

  private addGearMessages(message: ChatMessage) {
    if (message.data?.gears?.length) {
      const gearMessage = message.data.gears.map((gear) => {
        const gearLinkText = {
          text: `${this.translate.instant('Explore')} ${gear.brand}`,
          link: gear?.link,
        };
        const gearLabel = gear.name;

        return {
          text: gearLabel,
          link: gearLinkText,
          image: gear?.images ? gear?.images[0]?.file_url : '',
        };
      });

      this.messages.push({
        text: '',
        label: '',
        sent_at: new Date(),
        gearOptions: gearMessage,
        is_system: true,
      });
    }
  }

  private addLinkAccountsMessage(message: ChatMessage) {
    if (message.data?.link_accounts?.length) {
      const linkAccountsOptions = message.data.link_accounts.map((i) => ({
        name: i.name,
        logo: LINK_ACCOUNTS_IMAGES.find((j) => j.name === i.name)?.image || '',
      }));
      this.messages.push({
        text: this.formatMessage(
          message.data.message,
          message?.data?.links || [],
        ),
        sent_at: new Date(),
        link_accounts_options: linkAccountsOptions,
        is_system: true,
      });
    }
  }

  private choicePartnerBotMessage(message: ChatMessage) {
    if (message.data?.partner_bots?.length) {
      const partnerBotOptions = message.data.partner_bots.map((i, index) => ({
        name: i.name,
        descImgUrl: i.descImgUrl,
        description: i.description,
        botId: i.botId,
        disabled: index >= 1,
      }));
      this.messages.push({
        text: this.formatMessage(
          message.data.message,
          message?.data?.links || [],
        ),
        sent_at: new Date(),
        partner_bot_options: partnerBotOptions,
        is_system: true,
      });
    }
  }

  private addPartnerBotInfo(message: ChatMessage) {
    if (message.data?.partner_bot_info) {
      this.isPromptsLoading = false;
      this.isChatLoading = false;
      const partnerBotsInfo = message.data?.partner_bot_info;
      this.partnerBotMessages.push({
        sent_at: new Date(),
        text: partnerBotsInfo.description,
        is_system: true,
      });
      this.partnerBotInfo = partnerBotsInfo;
      this.prompts = this.partnerBotInfo.questions || PARTNER_PROMPTS_LIST;
    }
  }

  private partnerWebsiteLink(message: ChatMessage) {
    if (message.data?.partner_site_link) {
      const partnerSiteLink = {
        name: message.data?.partner_site_link.name,
        logo:
          PARTNER_BOTS_IMAGES.find(
            (j) => j.name === message.data?.partner_site_link.name,
          )?.image || '',
        url: message.data?.partner_site_link.url,
      };
      this.messages.push({
        text: '',
        sent_at: new Date(),
        partner_site: [partnerSiteLink],
        is_system: true,
      });
    }
  }

  formatMessage(message: string, links: ChatMessageLink[]): SafeHtml {
    if (!message?.length) return '';

    let formattedMessage = message
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\n/g, '<br>')
      .replace(/### (.*?)(<br>|$)/g, '<h3>$1</h3>');

    formattedMessage = formattedMessage.replace(
      /\[P(\d+)\]/g,
      (match, pIndex) => {
        const link = links.find((l) => l.reference === parseInt(pIndex, 10));

        return link
          ? `
        <a href="${link.url}" target="_blank" class="external-link">
            <img
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIEdlbmVyYXRvcjogU1ZHIFJlcG8gTWl4ZXIgVG9vbHMgLS0+DQo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPGcgaWQ9IkludGVyZmFjZSAvIEV4dGVybmFsX0xpbmsiPg0KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTEwLjAwMDIgNUg4LjIwMDJDNy4wODAwOSA1IDYuNTE5NjIgNSA2LjA5MTggNS4yMTc5OUM1LjcxNTQ3IDUuNDA5NzMgNS40MDk3MyA1LjcxNTQ3IDUuMjE3OTkgNi4wOTE4QzUgNi41MTk2MiA1IDcuMDgwMDkgNSA4LjIwMDJWMTUuODAwMkM1IDE2LjkyMDMgNSAxNy40ODAxIDUuMjE3OTkgMTcuOTA3OUM1LjQwOTczIDE4LjI4NDIgNS43MTU0NyAxOC41OTA1IDYuMDkxOCAxOC43ODIyQzYuNTE5MiAxOSA3LjA3ODk5IDE5IDguMTk2OTEgMTlIMTUuODAzMUMxNi45MjEgMTkgMTcuNDggMTkgMTcuOTA3NCAxOC43ODIyQzE4LjI4MzcgMTguNTkwNSAxOC41OTA1IDE4LjI4MzkgMTguNzgyMiAxNy45MDc2QzE5IDE3LjQ4MDIgMTkgMTYuOTIxIDE5IDE1LjgwMzFWMTRNMjAgOVY0TTIwIDRIMTVNMjAgNEwxMyAxMSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9nPg0KPC9zdmc+"
                alt="External link"
                style="width: 16px; height: 16px; filter: invert(43%) sepia(13%) saturate(4555%) hue-rotate(206deg) brightness(103%) contrast(104%)"
            />
        </a>`
          : match;
      },
    );

    return this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
  }

  checkConnectionStatus() {
    const onlineEvent = fromEvent(window, 'online');
    const offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      onlineEvent.subscribe(() => {
        this.isUserOnline = true;
      }),
    );

    this.subscriptions.push(
      offlineEvent.subscribe(() => {
        this.isUserOnline = false;
        this.removeLoadingMessage();
        this.scrollToBottom();
      }),
    );
  }

  convertTableToElementData(table: string[][]) {
    this.displayedColumns = table[0].map((header: string) => header);

    this.displayedColumns = table[0].map((header: string) => header);

    const rows = table.slice(1).map((row: string[]) => {
      const rowObj: { [key: string]: string } = {};
      row.forEach((cell: string, index: number) => {
        rowObj[this.displayedColumns[index]] = this.translate.instant(cell);
      });
      return rowObj;
    });

    if (this.newDataSource) {
      this.newDataSource.data = rows;
    }

    return rows;
  }

  sendWidgetOption(options: string[]) {
    this.messages.push({
      text: '',
      is_options_message: true,
      is_system: false,
      sent_at: new Date(),
      is_widget_options: true,
      options,
    });
  }

  private pushMessage(newMessage: MappedChatMessage) {
    if (this.showPartnerChat) {
      this.partnerBotMessages.push(newMessage);
    } else {
      this.messages.push(newMessage);
    }
  }

  private getScrollChatElement() {
    if (this.showPartnerChat) {
      return this.partnerChat?.nativeElement;
    } else {
      return this.chatContainer?.nativeElement;
    }
  }

  private updateLastMessage(
    lastMessage: MappedChatMessage,
    message: ChatMessage,
  ) {
    this.isMessageLoading = false;
    lastMessage.loading = false;
    lastMessage.text =
      this.formatMessage(message.data?.message, message?.data?.links || []) ||
      '';
    if (message.data?.options) {
      this.pushMessage({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      });
    }

    if (message.data?.gpx) {
      this.path = message?.data?.gpx.tracks.map((i) => this.getMappedPath(i));

      if (this.path) {
        this.path.map((i: MappedPathOutput[], index) => {
          const photo = this.googleMapsService.getStreetViewPhotoUrl(
            i[0].lat,
            i[0].lng,
          );
          this.segment_tracks?.push({
            photo_url: photo || '',
            segment: `Segment ${index + 1}`,
            elevationGain: i[0]?.elevationGain || 0,
            totalSimilarity: i[0]?.totalSimilarity || 0,
            distance: i[0].distance || 0,
          });
        });
      }
      this.disableLastMapMessage();

      this.pushMessage({
        text: '',
        is_options_message: false,
        is_system: true,
        sent_at: new Date(),
        is_map: true,
        options: message.data?.options || [],
      });
    }

    if (message?.data?.widgets) {
      let options: string[] = [];

      if (message?.data?.widgets[0] === 'SEGMENT_PICKER') {
        options = [CHOOSE_SEGMENT_OPTION];
      }

      if (message?.data?.widgets[0] === 'RADIUS_PICKER') {
        if (this.radius_center_point) {
          options = [SET_RADIUS_OPTION];
        } else {
          options = [SHOW_MAP_OPTION];
        }
      }

      if (
        options?.length &&
        message?.data?.widgets[0] === 'RADIUS_PICKER' &&
        !this.radius_center_point
      ) {
        this.sendWidgetOption(options);
      }
      this.pushMessage({
        text: '',
        is_system: true,
        sent_at: new Date(),
        widget: message?.data?.widgets[0],
      });

      if (
        (options?.length && message?.data?.widgets[0] === 'SEGMENT_PICKER') ||
        (message?.data?.widgets[0] === 'RADIUS_PICKER' &&
          this.radius_center_point)
      ) {
        this.sendWidgetOption(options);
      }
    }

    if (message?.data?.table?.length) {
      const table = this.convertTableToElementData(message?.data?.table);
      this.pushMessage({
        text: '',
        is_options_message: true,
        is_system: true,
        sent_at: new Date(),
        table: table,
      });
    }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
    this.choicePartnerBotMessage(message);
    this.partnerWebsiteLink(message);
  }

  onFitnessClick(provider: string) {
    this.fitnessAccountService
      .init(environment.fitnessServiceApiKey)
      .then(() => {
        this.fitnessAccountService.onSessionCreated().subscribe((data) => {
          if (data) {
            const sessionId = data?.session_id;

            this.hasRunkeeperTokenProcessed = false;
            const conversationGuid = localStorage.getItem(
              CONVERSATION_GUID,
            ) as string;
            if (provider === 'runkeeper') {
              this.oidcService.openAuthWindow(
                provider,
                conversationGuid,
                sessionId,
              );
            } else {
              window.open(
                `${window.location.origin}/fitness-account-linking/${provider}/${conversationGuid}/${sessionId}`,
                '_blank',
                'width=500, height=700',
              );
            }
          }
        });
        this.fitnessAccountService.onTokenReceived().subscribe((data) => {
          if (data && this.conversation_guid) {
            this.chatService.linkFitnessAccount(data, this.conversation_guid);
            this.fitnessAccountService.disconnect();
          }
        });
      });
  }

  getMappedPath(track: Track | GpxWaypoints): MappedPathOutput[] {
    if (track && track?.geometry && track?.properties) {
      const { distance, elevationGain, totalSimilarity } = track.properties;

      if (
        track?.properties?.distance &&
        track?.properties?.elevationGain &&
        track?.properties?.totalSimilarity
      ) {
        return track.geometry.coordinates.map((i) => ({
          lat: i.lat,
          lng: i.lon,
          distance: Number(distance?.toFixed(1)) || 0,
          elevationGain: Number(elevationGain?.toFixed(0)) || 0,
          totalSimilarity: Number(totalSimilarity?.toFixed(0)) || 0,
        }));
      } else {
        return track?.geometry?.coordinates.map((i) => ({
          lat: i.lat,
          lng: i.lon,
        }));
      }
    } else {
      return [];
    }
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  onLinkClick(gearMessage?: GearChatMessage) {
    if (gearMessage) {
      window.parent.postMessage(
        {
          type: 'analytics:track',
          payload: {
            event_name: ANALYTICS_EVENTS.USER_CLICKED_CONTENT,
            data: {
              title: gearMessage?.text,
              link: gearMessage.link.link,
            },
          },
        },
        '*',
      );
    }
  }

  onOptionClick(option: string, isRaceOptions: boolean = false) {
    if (isRaceOptions) {
      const selectedRace = this.races?.find((i) => i.name === option);
      if (selectedRace && selectedRace.guid) {
        this.selectRace(selectedRace.guid);
      }
      return;
    }
    if (
      option === CHOOSE_SEGMENT_OPTION ||
      option === SHOW_MAP_OPTION ||
      option === SET_RADIUS_OPTION
    ) {
      if (option === CHOOSE_SEGMENT_OPTION && this.segment_coordinates) {
        this.chatService.sendSegmentCoordinatesMessage({
          start_index: this.segment_coordinates?.startPointIndex,
          end_index: this.segment_coordinates?.endPointIndex,
          selected_distance: this.segment_distance,
        });
      }

      if (option === SHOW_MAP_OPTION) {
        const lastMessageIndex = this.messages.length - 2;
        const lastMessage = this.messages[lastMessageIndex];

        if (lastMessage.options) {
          delete lastMessage.options;
          lastMessage.text = SHOW_MAP_OPTION;
          lastMessage.is_options_message = false;
          lastMessage.is_widget_options = false;
        }

        this.messages[lastMessageIndex] = lastMessage;

        this.scrollToBottom();

        if (this.radius_center_point) {
          this.messages.push({
            text: '',
            is_options_message: true,
            is_system: false,
            sent_at: new Date(),
            is_widget_options: true,
            options: [SET_RADIUS_OPTION],
          });
          return;
        }
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.radius_center_point = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.messages.push({
              text: '',
              is_options_message: true,
              is_system: false,
              sent_at: new Date(),
              is_widget_options: true,
              options: [SET_RADIUS_OPTION],
            });
          },
          (error) => {
            this.radius_center_point = {
              lat: this.start_point?.lat || 40.73061,
              lng: this.start_point?.lng || -73.935242,
            };
            this.messages.push({
              text: '',
              is_options_message: true,
              is_system: false,
              sent_at: new Date(),
              is_widget_options: true,
              options: [SET_RADIUS_OPTION],
            });
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
          },
        );
        return;
      }

      if (
        option === SET_RADIUS_OPTION &&
        this.race_radius &&
        this.radius_center_point
      ) {
        const lastMessageIndex = this.messages.length - 1;

        const lastMessage = this.messages[lastMessageIndex];
        if (lastMessage.options) {
          delete lastMessage.options;
          lastMessage.text = SET_RADIUS_OPTION;
          lastMessage.is_options_message = false;
          lastMessage.is_widget_options = false;
        }
        this.messages[lastMessageIndex] = lastMessage;

        this.scrollToBottom();
        const data: RaceRadiusData = {
          radius: this.race_radius,
          point: {
            lat: this.radius_center_point.lat,
            lon: this.radius_center_point.lng,
          },
        };
        this.chatService.sendMapRadiusMessage(data);
        this.addLoadingMessage();
        return;
      }
    }
    if (this.conversation_guid) {
      this.chatService.sendMessage(option, this.conversation_guid);
    }

    const lastMessageIndex = this.messages.length - 1;
    const lastMessage = this.messages[lastMessageIndex];

    if (lastMessage.options) {
      delete lastMessage.options;
    }

    this.messages[lastMessageIndex] = lastMessage;

    const translatedOption = this.translate.instant(option);

    this.messages.push({
      text: translatedOption,
      is_system: false,
      sent_at: new Date(),
    });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  closeDrawer() {
    const message = { type: 'closeDrawer', payload: null };
    window.parent.postMessage(message, '*');
  }

  sendMessage() {
    if (this.userMessage.trim() && this.conversation_guid) {
      this.chatService.sendChangeScenarioMessage(
        AI_REQUESTS[0],
        this.conversation_guid,
      );

      this.pushMessage({
        text: this.userMessage.trim(),
        is_system: false,
        sent_at: new Date(),
      });
      this.scrollToBottom();
      this.addLoadingMessage();
      setTimeout(() => {
        if (this.conversation_guid) {
          this.chatService.sendMessage(
            this.userMessage.trim(),
            this.conversation_guid,
          );
          this.userMessage = '';
        }
      }, 400);
    }
  }

  disableLastMapMessage() {
    let foundItem;

    for (let i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages[i].is_map) {
        foundItem = this.messages[i];
        break;
      }
    }

    if (foundItem) {
      foundItem.disabled = true;
    }
  }

  onSegmentSelected(
    event: {
      startPointIndex: number;
      endPointIndex: number;
      segmentDistance: number;
    } | null,
  ) {
    if (event && event?.startPointIndex < event?.endPointIndex) {
      this.segment_coordinates = {
        startPointIndex: event.startPointIndex,
        endPointIndex: event.endPointIndex,
      };
      this.segment_distance = event?.segmentDistance;
    } else {
      this.segment_coordinates = null;
    }
  }

  onRadiusSelect(event: { radius: number; center: Coordinates }) {
    this.race_radius = event?.radius;
    this.radius_center_point = event?.center;
  }

  onPromptClick(prompt: string) {
    if (!this.conversation_guid) return;
    window.parent.postMessage(
      {
        type: 'analytics:track',
        payload: {
          event_name: ANALYTICS_EVENTS.USER_CLICKED_QUERY,
          data: {
            prompt,
          },
        },
      },
      '*',
    );
    const translatedMessage = this.translate.instant(prompt);
    if (ADDITIONAL_PROMPTS_LIST.includes(prompt)) {
      this.chatService.sendChangeScenarioMessage(
        AI_REQUESTS[0],
        this.conversation_guid,
      );
    }
    setTimeout(() => {
      if (!this.conversation_guid) return;
      const message = ADDITIONAL_PROMPTS_LIST.includes(prompt)
        ? this.translate.instant(prompt)
        : prompt;
      this.chatService.sendMessage(message, this.conversation_guid);
    }, 400);
    this.pushMessage({
      text: translatedMessage,
      is_system: false,
      sent_at: new Date(),
    });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  regenerateResponse() {
    this.AIRequestError = false;

    const message = this.messages[this.messages.length - 1];

    this.chatService.disconnect();

    this.chatService
      .init(this.raceId, this.access_token, this.session_user_guid)
      .then(() => {
        this.chatService.onConnectionReady().subscribe(() => {
          setTimeout(() => {
            if (!this.conversation_guid) return;
            this.chatService.sendChangeScenarioMessage(
              AI_REQUESTS[0],
              this.conversation_guid,
            );
            this.scrollToBottom();
            this.addLoadingMessage();
            setTimeout(() => {
              if (!this.conversation_guid) return;
              this.chatService.sendMessage(
                message?.text.toString(),
                this.conversation_guid,
              );
              this.userMessage = '';
            }, 100);
          }, 100);
        });
      });
  }

  onAuthChange(event: { access_token: string; refresh_token: string }) {
    if (event.access_token && event.refresh_token) {
      this.access_token = event.access_token;
      this.refresh_token = event.refresh_token;
      localStorage.setItem(ACCESS_TOKEN, this.access_token);
      localStorage.setItem(REFRESH_TOKEN, this.refresh_token);

      // const decoded = this.decodeAccessToken(this.access_token);
      // if (decoded) {
      //   this.user_guid = decoded?.sub;
      // }

      this.postAccessTokenMessage(this.access_token);

      this.loadRaceData();
      this.getCurrentRace();
    }
  }

  handleRaceChange(raceId: string) {
    this.selectRace(raceId);
    if (raceId !== '1') {
      this.prompt = '';
    }
  }

  postAccessTokenMessage(token: string) {
    window.parent.postMessage({ type: 'accessToken', payload: token }, '*');
  }

  handleLanguageChange(language: string) {
    const formattedLanguage = language.toLowerCase();
    this.language = formattedLanguage;
    this.translate.use(formattedLanguage);
    localStorage.setItem(SELECTED_LANGUAGE, formattedLanguage);

    window.parent.postMessage(
      { type: 'userLanguage', payload: formattedLanguage },
      '*',
    );

    setTimeout(() => {
      if (this.user_guid && this.selectedPartner?.botId) {
        this.chatService.sendPartnerBotChoiceMessage(
          this.user_guid,
          this.selectedPartner?.botId,
          this.raceId,
        );
      }
    }, 500);
    this.chatService.sendUpdateUserLanguage(formattedLanguage);
  }

  onOpenChatClick(partner: PartnerBots) {
    if (partner?.disabled || !this.conversation_guid || !this.user_guid) return;
    this.isPromptsLoading = true;
    this.showPartnerChat = true;
    this.selectedPartner = partner;
    this.chatService.sendPartnerBotChoiceMessage(
      this.user_guid,
      partner.botId,
      this.raceId,
    );
    this.partnerBotId = partner.botId;
    this.isChatLoading = true;
    this.isChatHistoryLoading = true;
  }

  onCloseChat() {
    if (!this.user_guid || !this.raceId || !this.selectedPartner) return;
    this.showPartnerChat = false;
    this.chatService.closePartnerChat(
      this.user_guid,
      this.selectedPartner?.botId,
      this.raceId,
    );
    this.selectedPartner = null;
    this.partnerBotMessages = [];
    this.scrollToBottom();
    this.prompts = [...ADDITIONAL_PROMPTS_LIST, ...WIDGET_PROMPTS_LIST];
    const conversationGuid = localStorage.getItem(CONVERSATION_GUID) as string;
    if (conversationGuid) {
      this.conversation_guid = conversationGuid;
    }
  }

  onTabChanged(event: { index: number }) {
    const clickedIndex = event.index;
    this.selectedTabIndex = clickedIndex;
    if (clickedIndex === 0) {
      this.scrollToBottom('immediate');
    }
  }

  changeActiveTab(index: number) {
    this.selectedTabIndex = index;
  }

  onPageReload() {
    window.location.reload();
  }

  refreshMap() {
    this.shouldRenderMap = false;
    this.mapConfig = null;
    setTimeout(() => {
      this.shouldRenderMap = true;
    }, 0);
  }

  onAccountClick() {
    if (this.keycloakToken) {
      window.open('https://run-concierge-dev.neurun.com/', '_blank');
      return;
    }
    this.keycloakLogin();
    this.showLogin = !this.showLogin;
  }
}
