<div class="container">
  <div *ngIf="currentStep === 1">
    <app-auth-header [title]="'auth.Create an account' | translate" [subTitle]="'auth.Log in or create a free account to bookmark messages and revisit past conversations.' | translate"></app-auth-header>
<!--    <div style="margin-top: 20px; margin-bottom: 24px">-->
<!--      <app-social-login></app-social-login>-->
<!--    </div>-->
<!--    <div class="divider-container">-->
<!--      <div class="line"><mat-divider class="divider"></mat-divider></div>-->
<!--      <div class="divider-text">or</div>-->
<!--      <div class="line"><mat-divider class="divider"></mat-divider></div>-->
<!--    </div>-->
    <div *ngIf="errorMessage" style="text-align: center">
      <mat-error>
        {{ ('auth.' + errorMessage) | translate }}
      </mat-error>
    </div>
    <div style="margin-top: 24px">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div>
          <span class="input-label">{{ 'auth.Email Address' | translate }}</span>
          <mat-form-field>
            <input matInput autocomplete="off" id="email" formControlName="email" placeholder="example@neurun.com">
          </mat-form-field>
        </div>
        <div style="margin-top: 40px; margin-bottom: 16px">
          <app-custom-button type="submit" [title]="'actions.Next' | translate" variant="primary" [disabled]="!registerForm.valid"></app-custom-button>
        </div>
      </form>
      <span style="font-weight: 500; text-align: center; display: flex; align-items: center; justify-content: center; gap: 4px;">
        {{ 'auth.Already have an account?' | translate }}<span class="action-button" (click)="onLoginClick()">{{ 'auth.Login here' | translate }}</span></span>
      <div style="margin-top: 30px; text-align: center">
      <span>
        {{ 'auth.By continuing, you agree to Neurun’s' | translate }} <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=b084bde0-aebd-4b00-a31a-c02f05e839a8" target="_blank" style="color: #6271FF">
        {{ 'auth.terms and conditions' | translate }}
      </a>.
      </span>
      </div>
    </div>
  </div>
  <div *ngIf="currentStep === 2" style="width: 100%;">
    <app-auth-header [title]="'auth.Welcome! Let’s get to know you' | translate"></app-auth-header>
    <div style="margin-top: 24px">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div>
          <span class="input-label">{{ 'auth.First Name' | translate }}</span>
          <mat-form-field>
            <input matInput autocomplete="off" id="first_name" formControlName="first_name">
          </mat-form-field>
        </div>
        <div style="margin-top: 12px">
          <span class="input-label">{{ 'auth.Last Name' | translate }}</span>
          <mat-form-field>
            <input matInput autocomplete="off" id="last_name" formControlName="last_name">
          </mat-form-field>
        </div>
        <div style="margin-top: 40px; margin-bottom: 16px; bottom: 30px; width: 100%">
          <app-custom-button type="submit" [title]="'actions.Next' | translate" variant="primary" [disabled]="!registerForm.valid"></app-custom-button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="currentStep === 3" style="width: 100%">
    <app-auth-header [title]="'auth.Create a password' | translate"></app-auth-header>
    <div style="margin-top: 24px">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div>
          <span class="input-label">{{ 'auth.Login Password' | translate }}</span>
          <mat-form-field>
            <input matInput autocomplete="off" id="password" formControlName="password">
          </mat-form-field>
        </div>
        <div>
          <p style="font-weight: 500; font-size: 16px; margin-top: 8px">{{ 'auth.Your password must contain:' | translate }}</p>
          <div class="list-container">
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasMinLength; else emptyBlock" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE3NTVfODEwNCIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iLTEiIHk9Ii0xIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSItMC4wNDQ5MjE5IiB5PSItMC4xMzQ1MjEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTc1NV84MTA0KSI+CjxwYXRoIGQ9Ik05LjUwNDQyIDE3LjUxOTNMNC4xNjk5MiAxMi4xODQ4TDUuMjM4OTIgMTEuMTE1Nkw5LjUwNDQyIDE1LjM4MTFMMTguNjY5OSA2LjIxNTU4TDE5LjczODkgNy4yODQ4M0w5LjUwNDQyIDE3LjUxOTNaIiBmaWxsPSIjNjI3MUZGIi8+CjwvZz4KPC9zdmc+Cg==" alt="Check"
                   height="24" width="24"/>
              {{ 'auth.8-32 characters long' | translate }}
            </div>
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasLowerCase; else emptyBlock" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE3NTVfODEwNCIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iLTEiIHk9Ii0xIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSItMC4wNDQ5MjE5IiB5PSItMC4xMzQ1MjEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTc1NV84MTA0KSI+CjxwYXRoIGQ9Ik05LjUwNDQyIDE3LjUxOTNMNC4xNjk5MiAxMi4xODQ4TDUuMjM4OTIgMTEuMTE1Nkw5LjUwNDQyIDE1LjM4MTFMMTguNjY5OSA2LjIxNTU4TDE5LjczODkgNy4yODQ4M0w5LjUwNDQyIDE3LjUxOTNaIiBmaWxsPSIjNjI3MUZGIi8+CjwvZz4KPC9zdmc+Cg==" alt="Check"
                   height="24" width="24"/>
              {{ 'auth.1 lowercase character (a-z)' | translate }}
            </div>
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasUpperCase; else emptyBlock" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE3NTVfODEwNCIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iLTEiIHk9Ii0xIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSItMC4wNDQ5MjE5IiB5PSItMC4xMzQ1MjEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTc1NV84MTA0KSI+CjxwYXRoIGQ9Ik05LjUwNDQyIDE3LjUxOTNMNC4xNjk5MiAxMi4xODQ4TDUuMjM4OTIgMTEuMTE1Nkw5LjUwNDQyIDE1LjM4MTFMMTguNjY5OSA2LjIxNTU4TDE5LjczODkgNy4yODQ4M0w5LjUwNDQyIDE3LjUxOTNaIiBmaWxsPSIjNjI3MUZGIi8+CjwvZz4KPC9zdmc+Cg==" alt="Check"
                   height="24" width="24"/>
              {{ 'auth.1 uppercase character (A-Z)' | translate }}
            </div>
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasNumeric; else emptyBlock" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE3NTVfODEwNCIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iLTEiIHk9Ii0xIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSItMC4wNDQ5MjE5IiB5PSItMC4xMzQ1MjEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTc1NV84MTA0KSI+CjxwYXRoIGQ9Ik05LjUwNDQyIDE3LjUxOTNMNC4xNjk5MiAxMi4xODQ4TDUuMjM4OTIgMTEuMTE1Nkw5LjUwNDQyIDE1LjM4MTFMMTguNjY5OSA2LjIxNTU4TDE5LjczODkgNy4yODQ4M0w5LjUwNDQyIDE3LjUxOTNaIiBmaWxsPSIjNjI3MUZGIi8+CjwvZz4KPC9zdmc+Cg==" alt="Check"
                   height="24" width="24"/>
              {{ 'auth.1 number' | translate }}
            </div>
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasSpecial; else emptyBlock" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE3NTVfODEwNCIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iLTEiIHk9Ii0xIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSItMC4wNDQ5MjE5IiB5PSItMC4xMzQ1MjEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTc1NV84MTA0KSI+CjxwYXRoIGQ9Ik05LjUwNDQyIDE3LjUxOTNMNC4xNjk5MiAxMi4xODQ4TDUuMjM4OTIgMTEuMTE1Nkw5LjUwNDQyIDE1LjM4MTFMMTguNjY5OSA2LjIxNTU4TDE5LjczODkgNy4yODQ4M0w5LjUwNDQyIDE3LjUxOTNaIiBmaWxsPSIjNjI3MUZGIi8+CjwvZz4KPC9zdmc+Cg==" alt="Check"
                   height="24" width="24"/>
              {{ 'auth.1 special character e.g. !&#@$%' | translate }}
            </div>
          </div>
        </div>
        <ng-template #emptyBlock>
          <div style="width: 24px; height: 24px"></div>
        </ng-template>
        <div style="margin-top: 40px; margin-bottom: 16px; bottom: 30px; width: 100%">
          <app-custom-button type="submit" [title]="'actions.Create Account' | translate" variant="primary" [disabled]="!registerForm.valid"></app-custom-button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="currentStep === 4" style="width: 100%; position: relative">
    <app-auth-header
      [title]="'auth.Success! Your account has been created.' | translate"
      [subTitle]="'auth.confirmationEmail' | translate:{ email: registerForm.value.email }"
      [isSuccess]="true"
    ></app-auth-header>
    <div style="margin-top: 30px; margin-bottom: 16px; bottom: 30px; width: 100%">
      <app-custom-button type="submit" [title]="'actions.Start Exploring' | translate" variant="primary"></app-custom-button>
    </div>
    <div style="margin-top: 16px; margin-bottom: 16px; bottom: 30px; width: 100%">
      <app-custom-button type="submit" [title]="'actions.Start Exploring' | translate" variant="outlined"></app-custom-button>
    </div>
  </div>
</div>
