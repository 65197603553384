import { Injectable } from '@angular/core';
import io, { Socket } from 'socket.io-client';
import { catchError, Observable, throwError, Subject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {
  ChatErrorMessage,
  ChatMessage,
  LinkFitnessAccountPayload,
  OnConnectionReadyResponse,
  PartnerBotChat,
  RaceRadiusData,
  RaceSegmentCoordinatesIndex,
  RawSttMessage,
  SttMessage,
} from '../../../types/models';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CONVERSATION_SCENARIO } from '../../../constants';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private socket: Socket | null = null;
  private apiUrl: string = environment.wsUrl;
  private baseUrl: string = environment.baseUrl;
  private messageSubject = new Subject<ChatMessage>();
  private reconnectionSubject = new Subject<void>();
  private onConnectionReadySubject = new Subject<OnConnectionReadyResponse>();
  private isFirstConnection = true;

  constructor(
    private readonly keycloakService: KeycloakService,
    private http: HttpClient,
  ) {}

  async init(
    raceGuid: string | null,
    keycloakToken?: string | null,
    user_guid?: string | null,
  ) {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }

    const token = keycloakToken || (await this.keycloakService.getToken());
    if (token && raceGuid) {
      const params = new URLSearchParams();
      params.append('race_guid', raceGuid);
      if (user_guid) {
        params.append('user_guid', user_guid);
      }

      const socketUrl = `${this.apiUrl}?${params.toString()}`;

      this.socket = io(socketUrl, {
        transports: ['websocket'],
        path: '/api/ws/neurun',
        ackTimeout: 3 * 60 * 1000,
        auth: {
          token: `Bearer ${token}`,
        },
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 5000,
      });

      this.socket.on('connect_error', () => {
        this.cleanupListeners();
      });

      this.setupSocketListeners();
    }
  }

  public getMessagesStream() {
    return this.messageSubject.asObservable();
  }

  public getReconnectionObservable(): Observable<void> {
    return this.reconnectionSubject.asObservable();
  }

  public getOnConnectionObservable(): Observable<OnConnectionReadyResponse> {
    return this.onConnectionReadySubject.asObservable();
  }

  private cleanupListeners() {
    if (this.socket) {
      this.socket.off('ON_CONNECTION_READY');
      this.socket.off('ON_MESSAGE_RECEIVED');
      this.socket.off('ON_SCENARIO_CHANGED');
      this.socket.off('ON_SPEECH_DATA');
      this.socket.off('ON_RAW_SPEECH_DATA');
      this.socket.off('ON_CHAT_HISTORY_RESTORE');
      this.socket.off('ON_EVENT_ERROR');
    }
  }

  onConnectionReady() {
    return new Observable<{ conversation_guid: string; user_guid: string }>(
      (observer) => {
        if (this.socket) {
          const handler = (data: OnConnectionReadyResponse) => {
            observer.next(data);
          };
          this.socket.on('ON_CONNECTION_READY', handler);
          return () => {
            if (this.socket) {
              this.socket.off('ON_CONNECTION_READY', handler);
            }
          };
        } else {
          throw Error('connection error');
        }
      },
    );
  }

  onSwitchPartnerBot() {
    return new Observable<PartnerBotChat>((observer) => {
      if (this.socket) {
        const handler = (data: PartnerBotChat) => {
          observer.next(data);
        };
        this.socket.on('SWITCH_TO_PARTNER_BOT', handler);

        return () => {
          if (this.socket) {
            this.socket.off('SWITCH_TO_PARTNER_BOT', handler);
          }
        };
      } else {
        throw Error('switch to partner bot error');
      }
    });
  }

  startGoogleStt() {
    if (this.socket) {
      this.socket.volatile.emit('SEND_ASR_STREAM_CONNECT');
    }
  }

  sendGoogleSttData(data: ArrayBuffer) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_ASR_DATA', data);
    }
  }

  stopGoogleStt() {
    if (this.socket) {
      this.socket.volatile.emit('SEND_ASR_STREAM_DISCONNECT');
    }
  }

  requestChatHistory(take: number, from: Date, conversation_guid: string) {
    if (this.socket) {
      const data: { take: number; from: Date; botId?: string } = {
        take,
        from,
      };

      this.socket.volatile.emit('CHAT_HISTORY_RESTORE', {
        type: 'REQUEST_CHAT_HISTORY',
        data,
        conversation_guid,
      });
    }
  }

  sendMessage(message: string, conversation_guid: string) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        type: 'TEXT',
        data: {
          message,
        },
        conversation_guid,
      });
    }
  }

  linkFitnessAccount(
    data: LinkFitnessAccountPayload,
    conversation_guid: string,
  ) {
    if (this.socket) {
      const payload = { ...data, conversation_guid };
      this.socket.volatile.emit('LINK_FITNESS_ACCOUNT', payload);
    }
  }

  closePartnerChat(userGuid: string, raceGuid: string, partnerBotGuid: string) {
    if (this.socket) {
      this.socket.volatile.emit('LEFT_PARTNER_BOT', {
        userGuid,
        raceGuid,
        partnerBotGuid,
      });
    }
  }

  sendUpdateUserLanguage(language: string) {
    if (this.socket) {
      this.socket.volatile.emit('UPDATE_USER_LANGUAGE', {
        data: {
          language,
        },
      });
    }
  }

  sendLinkAccountMessage(
    token: string,
    accountType: string,
    tokenSecret?: string,
  ) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        type: 'ACCOUNT_LINK',
        data: {
          token: token,
          token_secret: tokenSecret,
          account_type: accountType,
        },
      });
    }
  }

  sendPartnerBotChoiceMessage(
    userGuid: string,
    partnerBotGuid: string,
    raceGuid: string,
  ) {
    if (this.socket) {
      this.socket.volatile.emit('SWITCH_TO_PARTNER_BOT', {
        userGuid,
        raceGuid,
        partnerBotGuid,
      });
    }
  }

  sendSegmentCoordinatesMessage(message: RaceSegmentCoordinatesIndex) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        type: 'RACE_SEGMENT',
        data: message,
      });
    }
  }

  sendMapRadiusMessage(message: RaceRadiusData) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        type: 'SEARCH_RADIUS',
        data: message,
      });
    }
  }

  sendChangeScenarioMessage(scenario: string, conversation_guid: string) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        type: 'CHANGE_SCENARIO',
        data: {
          scenario,
        },
        conversation_guid,
      });
    }
  }

  getMessages() {
    return new Observable<ChatMessage>((observer) => {
      if (this.socket) {
        const handler = (data: ChatMessage) => {
          observer.next(data);
        };
        this.socket.on('ON_MESSAGE_RECEIVED', handler);

        return () => {
          if (this.socket) {
            this.socket.off('ON_MESSAGE_RECEIVED', handler);
          }
        };
      } else {
        throw Error('get message error');
      }
    });
  }

  getSttMessage() {
    return new Observable<SttMessage>((observer) => {
      if (this.socket) {
        this.socket.on('ON_SPEECH_DATA', (data) => {
          observer.next(data);
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        };
      } else {
        throw Error('get stt message error');
      }
    });
  }

  getRawSttMessage() {
    return new Observable<RawSttMessage>((observer) => {
      if (this.socket) {
        this.socket.on('ON_RAW_SPEECH_DATA', (data) => {
          observer.next(data);
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        };
      } else {
        throw Error('get raw stt message error');
      }
    });
  }

  getChatHistory() {
    return new Observable<{
      messages: ChatMessage[];
      take: number;
      from: number;
      total_count: number;
    }>((observer) => {
      if (this.socket) {
        this.socket.on('ON_CHAT_HISTORY_RESTORE', (data) => {
          observer.next(data);
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        };
      } else {
        throw Error('get chat history error');
      }
    });
  }

  getError() {
    return new Observable<ChatErrorMessage>((observer) => {
      if (this.socket) {
        this.socket.on('ON_EVENT_ERROR', (data) => {
          observer.next(data);
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        };
      } else {
        throw Error('get error error');
      }
    });
  }

  getConversationScenario() {
    return new Observable<{
      scenario: CONVERSATION_SCENARIO;
    }>((observer) => {
      if (this.socket) {
        this.socket.on('ON_SCENARIO_CHANGED', (data) => {
          observer.next(data);
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        };
      } else {
        throw Error('get scenario error');
      }
    });
  }

  disconnect() {
    if (this.socket) {
      this.cleanupListeners();
      this.socket.disconnect();
      this.socket = null;
      this.isFirstConnection = true;
    }
  }

  deleteChatHistory() {
    return this.http
      .delete(`${this.baseUrl}/user/chat/history`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }

  private setupSocketListeners() {
    if (!this.socket) {
      return;
    }

    this.socket.on('connect', () => {
      console.log('Socket connected:', this.socket!.id);
      this.registerChatListeners();
      if (!this.isFirstConnection) {
        this.reconnectionSubject.next();
        console.log('This is a reconnection.');
      }
      this.isFirstConnection = false;
    });
  }

  private registerChatListeners() {
    if (this.socket) {
      this.socket.off('ON_MESSAGE_RECEIVED');
      this.socket.on(
        'ON_CONNECTION_READY',
        (data: OnConnectionReadyResponse) => {
          this.onConnectionReadySubject.next(data);
        },
      );
      this.socket.on('ON_MESSAGE_RECEIVED', (data: ChatMessage) => {
        this.messageSubject.next(data);
      });
    }
  }
}
