import { environment } from '../../environments/environment';

export function loadGoogleAnalytics() {
  if (environment.googleTagManagerId) {
    // Google tag manager
    const tagScript = document.createElement('script');
    tagScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.googleTagManagerId}')
  `;
    document.head.appendChild(tagScript);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.googleTagManagerId}"
                  height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noscript);
  }

  if (environment.googleAnalyticsId) {
    // Google Analytics
    const analyticsScript = document.createElement('script');
    analyticsScript.async = true;
    analyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
    document.head.appendChild(analyticsScript);

    const gtagScript = document.createElement('script');
    gtagScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${environment.googleAnalyticsId}');
  `;
    document.head.appendChild(gtagScript);
  }
}

export function loadGoogleMapsApi() {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}&libraries&v=alpha&libraries=places,geometry,maps3d,marker`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}
