import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { NgStyle, UpperCasePipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

export interface LanguageOption {
  code: string;
  label: string;
}

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [
    UpperCasePipe,
    MatSelectModule,
    MatOptionModule,
    NgStyle,
    NgSelectModule,
    FormsModule,
  ],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectorComponent implements OnInit, OnChanges {
  @Input() language: string = '';
  @Input() color: string = '';
  @Input() disabled?: boolean = false;
  @Input() isSearchBar?: boolean = false;
  @Output() languageChange = new EventEmitter<string>();
  languages: LanguageOption[] = [
    { code: 'EN', label: 'English' },
    { code: 'FR', label: 'French' },
    { code: 'DE', label: 'German' },
    { code: 'ES', label: 'Spanish' },
    { code: 'JA', label: 'Japanese' },
  ];
  selectedLanguage: string = 'EN';

  ngOnInit() {
    this.setLanguage();
  }

  onOpen() {
    if (!this.isSearchBar) {
      // setTimeout(() => {
      //   const panels = document?.body?.querySelectorAll('.ng-dropdown-panel');
      //   if (panels && panels.length > 0) {
      //     panels.forEach((panel) =>
      //       panel.classList.add('language-select-dropdown'),
      //     );
      //   } else {
      //     console.warn('No ng-dropdown-panel found.');
      //   }
      // }, 0);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainColor']?.currentValue) {
      const root = document.documentElement;
      root.style.setProperty('--main-color', this.color || '#6271FF');
    }

    if (changes['language']?.currentValue) {
      this.setLanguage();
    }
  }

  setLanguage() {
    const langFound = this.languages.find(
      (lang) => lang.code === this.language.toUpperCase(),
    );
    if (langFound) {
      this.selectedLanguage = langFound.code;
    }
  }

  onLanguageChange(event: { code: string }) {
    this.languageChange.emit(event.code);
  }
}
