import { Injectable } from '@angular/core';
import io, { Socket } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { FitnessAccountPayload } from '../../../types/models';

@Injectable({
  providedIn: 'root',
})
export class FitnessAccountSocketService {
  private socket: Socket | null = null;
  private apiUrl: string = environment.accountLinkingUrl;

  async init(apiKey: string | null) {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }

    if (apiKey) {
      const socketUrl = `${this.apiUrl}`;

      this.socket = io(socketUrl, {
        transports: ['websocket'],
        ackTimeout: 3 * 60 * 1000,
        auth: {
          api_key: apiKey,
        },
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 5000,
      });
    }
  }

  onSessionCreated() {
    return new Observable<{ session_id: string }>((observer) => {
      if (this.socket) {
        console.log('SESSION CREATED');
        const handler = (data: { session_id: string }) => {
          observer.next(data);
        };
        this.socket.on('SESSION_CREATED', handler);

        return () => {
          if (this.socket) {
            this.socket.off('SESSION_CREATED', handler);
          }
        };
      } else {
        throw Error('connection error');
      }
    });
  }

  onTokenReceived() {
    return new Observable<FitnessAccountPayload>((observer) => {
      if (this.socket) {
        const handler = (data: FitnessAccountPayload) => {
          observer.next(data);
        };
        this.socket.on('PAYLOAD_RECEIVED', handler);
        return () => {
          if (this.socket) {
            this.socket.off('PAYLOAD_RECEIVED', handler);
          }
        };
      } else {
        throw Error('connection error');
      }
    });
  }

  private cleanupListeners() {
    if (this.socket) {
      this.socket.off('PAYLOAD_RECEIVED');
      this.socket.off('CREATED');
    }
  }

  disconnect() {
    if (this.socket) {
      this.cleanupListeners();
      this.socket.disconnect();
      this.socket = null;
    }
  }
}
