import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';

@Component({
  selector: 'app-custom-button',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './custom-button.component.html',
  styleUrl: './custom-button.component.scss',
})
export class CustomButtonComponent {
  @Input() type: string = '';
  @Input() id: string = '';
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() variant: string = 'primary';
  @Input() customBackground: string = '';
  @Output() handleClick = new EventEmitter<string>();

  onCLick() {
    if (this.handleClick) {
      this.handleClick.emit();
    }
  }
}
