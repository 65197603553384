import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, from, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AnonymousUserInitResponse,
  CodeCheckResponse,
  IntrospectTokenResponse,
  User,
  UserFitnessInformation,
  UserGetTokenResponse,
  UserNameInfo,
  UserSettings,
  UserSettingsResponseDto,
} from '../../../types/models';
import axios from 'axios';
import { CacheService } from '../cache-service/cache.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl: string = environment.baseUrl;
  private wsUrl: string = environment.wsUrl;
  private getUserUrl: string = `${this.apiUrl}/user/myself`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
  ) {}

  getUser(): Observable<User> {
    if (this.cacheService.has(this.getUserUrl)) {
      return this.cacheService.get(this.getUserUrl) as Observable<User>;
    } else {
      return this.http.get<User>(this.getUserUrl).pipe(
        tap((response) => this.cacheService.set(this.getUserUrl, response)),
        catchError(this.handleError),
      );
    }
  }

  updateUser(
    data:
      | UserNameInfo
      | {
          fitness_information?: UserFitnessInformation;
          gender?: string;
          about_me?: string;
        }
      | UserSettings,
  ): Observable<UserNameInfo> {
    const url = `${this.apiUrl}/user/myself`;
    return this.http.put<UserNameInfo>(url, data, this.httpOptions).pipe(
      tap(() => this.cacheService.delete(this.getUserUrl)),
      catchError(this.handleError),
    );
  }

  updateUserSettings(data: UserSettings, token: string): Observable<User> {
    const url = `${this.apiUrl}/user/myself`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http
      .put<User>(url, data, { headers })
      .pipe(catchError(this.handleError));
  }

  uploadUserAvatar(file: FormData): Observable<{ url: string }> {
    const url = `${this.apiUrl}/user/avatar`;
    return this.http.post<{ url: string }>(url, file).pipe(
      tap(() => this.cacheService.delete(this.getUserUrl)),
      catchError(this.handleError),
    );
  }

  deleteUser() {
    const url = `${this.apiUrl}/user/myself`;
    return this.http.delete<{ url: string }>(url).pipe(
      tap(() => this.cacheService.delete(this.getUserUrl)),
      catchError(this.handleError),
    );
  }

  getRunkeeperProfileData(token: string) {
    return from(
      axios.get('https://ai-dev.neurun.com/api-rk/profile', {
        headers: {
          'Content-Type': 'application/vnd.com.runkeeper.Profile+json',
          Authorization: `Bearer ${token}`,
          accept: '*/*',
        },
      }),
    );
  }

  getRunkeeperUserWeightData(token: string) {
    return from(
      axios.get('https://ai-dev.neurun.com/api-rk/weight', {
        headers: {
          'Content-Type': 'application/vnd.com.runkeeper.Weight+json',
          Authorization: `Bearer ${token}`,
          accept: '*/*',
        },
      }),
    );
  }

  checkAccessCode(code: string): Observable<CodeCheckResponse> {
    const url = `${this.apiUrl}/user/invitation/${code}`;
    return this.http
      .get<CodeCheckResponse>(url)
      .pipe(catchError(this.handleError));
  }

  sendAccessCode(code: string): Observable<CodeCheckResponse> {
    const url = `${this.apiUrl}/user/invitation/${code}`;
    return this.http.post<CodeCheckResponse>(url, null);
  }

  sendActivityToken(data: {
    token_secret?: string;
    token: string;
    account_type: string;
  }): Observable<CodeCheckResponse> {
    const url = `${this.wsUrl}/api/ws/user-activity/token`;
    return this.http.post<CodeCheckResponse>(url, data);
  }

  sendActivityTokenWithAxios(data: {
    token_secret: string;
    token: string;
    account_type: string;
    conversation_guid: string;
    session_id: string;
  }): Observable<CodeCheckResponse> {
    const url = `${environment.accountLinkingUrl}/api/forward-payload`;

    return from(
      axios.post<CodeCheckResponse>(url, data, {
        headers: {
          'X-API-Key': environment.fitnessServiceApiKey,
        },
      }),
    ).pipe(
      map((response) => response.data),
      catchError((error) => {
        let errorMessage = 'An unknown error occurred!';
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        return throwError(() => errorMessage);
      }),
    );
  }

  loginUser(login: string, password: string): Observable<UserGetTokenResponse> {
    const url = `${this.apiUrl}/user/login`;
    return this.http
      .post<UserGetTokenResponse>(url, { login, password })
      .pipe(catchError(this.handleError));
  }

  registerUser(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ): Observable<UserGetTokenResponse> {
    const url = `${this.apiUrl}/user/register`;
    return this.http
      .post<UserGetTokenResponse>(url, {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      })
      .pipe(catchError(this.handleError));
  }

  resetPassword(email: string): Observable<UserGetTokenResponse> {
    const url = `${this.apiUrl}/user/reset-password`;
    return this.http
      .post<UserGetTokenResponse>(url, { email })
      .pipe(catchError(this.handleError));
  }

  refreshToken(refreshToken: string): Observable<UserGetTokenResponse> {
    const url = `${this.apiUrl}/user/refresh-token`;
    return this.http
      .post<UserGetTokenResponse>(url, { refresh_token: refreshToken })
      .pipe(catchError(this.handleError));
  }

  getUserSettings(token: string): Observable<UserSettingsResponseDto> {
    const url = `${this.apiUrl}/user/user-settings`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http
      .get<UserSettingsResponseDto>(url, { headers })
      .pipe(catchError(this.handleError));
  }

  initAnonymousUser(): Observable<AnonymousUserInitResponse> {
    const url = `${this.apiUrl}/user/anonymous/init`;
    return this.http
      .post<AnonymousUserInitResponse>(url, null)
      .pipe(catchError(this.handleError));
  }

  introspectToken(token: string): Observable<IntrospectTokenResponse> {
    const url = `${this.apiUrl}/user/token/introspect`;
    return this.http
      .post<IntrospectTokenResponse>(url, { token })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error.message) {
      errorMessage = error.error.message;
    }
    return throwError(() => errorMessage);
  }
}
