import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PromptsListComponent } from '../../components/prompts-list/prompts-list.component';
import { NgIf, NgStyle } from '@angular/common';
import { SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import {
  ACCESS_TOKEN,
  ADDITIONAL_PROMPTS_LIST,
  ANALYTICS_EVENTS,
  RACE_CONCIERGE_LOGO,
  RACE_CONCIERGE_SVG,
  SELECTED_LANGUAGE,
  SELECTED_RACE_ID,
  SUPPORTED_LANGUAGES,
  WIDGET_ARROW_FORWARD_LOGO,
  WIDGET_ARROW_FORWARD_SVG,
  WIDGET_PROMPTS_LIST,
  WIDGET_SEARCH_LOGO,
  WIDGET_SEARCH_SVG,
} from '../../../constants';
import { RaceService } from '../../services/race/race.service';
import { Race, RaceTypes, WidgetConfiguration } from '../../../types/models';
import { AiChatWrapperComponent } from '../ai-chat-wrapper/ai-chat-wrapper.component';
import { ScreenResizeService } from '../../services/screenResive/screen-resize.service';
import { convertToTitleCase } from '../../helpers/convertToTitleCase';
import { SvgLogoService } from '../../services/svgLogoService/svg-logo-service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LanguageSelectorComponent } from '../../components/language-selector/language-selector.component';
import { RaceSelectorComponent } from '../../components/race-selector/race-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../services/user/user.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';

type Coordinates = {
  lat: number;
  lng: number;
};

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    PromptsListComponent,
    NgIf,
    NgStyle,
    AiChatWrapperComponent,
    TranslatePipe,
    LanguageSelectorComponent,
    RaceSelectorComponent,
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SearchBarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() event!: string;
  @Input() demo!: string;
  @Input() configuration!: string;
  isDrawerOpen = false;
  prompts: string[] = [...ADDITIONAL_PROMPTS_LIST, ...WIDGET_PROMPTS_LIST];
  selectedPrompt: string = '';
  isVoiceMode: boolean = false;
  keycloakLoginUrl: SafeResourceUrl = '';
  showLoginIframe: boolean = false;
  raceId: string = 'b2a2005b-ab5e-4c69-939b-e6a8fe63547e';
  race: Race | null = null;
  path: Coordinates[] | null = null;
  userMessage: string = '';
  isAIDrawerOpen: boolean = false;
  token: string = '';
  races: Race[] = [];
  selectedRace: string = '';
  error: string = '';
  raceTypes: RaceTypes[] = [];
  isMobile: boolean = false;
  widgetConfiguration: WidgetConfiguration | null = null;
  promptColor: string = '#6271FF';
  raceConciergeLogo: SafeUrl = RACE_CONCIERGE_LOGO;
  raceConciergeSvgTemplate: string = RACE_CONCIERGE_SVG;
  searchLogo: SafeUrl = WIDGET_SEARCH_LOGO;
  searchSvgTemplate = WIDGET_SEARCH_SVG;
  arrowForwardLogo: SafeUrl = WIDGET_ARROW_FORWARD_LOGO;
  arrowForwardSvgTemplate: string = WIDGET_ARROW_FORWARD_SVG;
  browserLanguage: string = 'en';
  currentLanguage: string = 'en';
  access_token: string = '';

  constructor(
    private raceService: RaceService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private screenSizeService: ScreenResizeService,
    private svgLogoService: SvgLogoService,
    private translate: TranslateService,
    private userService: UserService,
    public dialog: MatDialog,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['configuration']) {
      if (this.configuration) {
        this.widgetConfiguration = JSON.parse(this.configuration);
        this.promptColor =
          this.widgetConfiguration?.style?.main_color || '#6271FF';
        this.raceConciergeLogo = this.svgLogoService.updateSvgLogo(
          this.raceConciergeSvgTemplate,
          this.promptColor,
          '#4C57BC',
        );

        this.searchLogo = this.svgLogoService.updateSvgLogo(
          this.searchSvgTemplate,
          this.promptColor,
          '#4C57BC',
        );

        this.arrowForwardLogo = this.svgLogoService.updateSvgLogo(
          this.arrowForwardSvgTemplate,
          this.promptColor,
          '#6271FF',
        );
      }
    }
  }

  async ngOnInit() {
    // Commented till we will integrate Gemini
    // this.browserLanguage =
    //   this.translate.currentLang || this.translate.getBrowserLang() || '';
    // this.currentLanguage = this.browserLanguage;
    // const selectedLanguage = localStorage.getItem(SELECTED_LANGUAGE);
    // this.translate.addLangs(SUPPORTED_LANGUAGES);
    // this.translate.setDefaultLang('en');
    const selectedLanguage = 'en';
    this.browserLanguage = 'en';
    this.currentLanguage = 'en';

    const token = localStorage.getItem(ACCESS_TOKEN);

    if (token) {
      this.access_token = token;
    }

    const browserLang =
      selectedLanguage || this.translate.getBrowserLang() || '';
    const languageToUse = SUPPORTED_LANGUAGES.includes(browserLang)
      ? browserLang
      : 'en';
    this.translate.use(languageToUse);
    this.currentLanguage = languageToUse;

    const selectedRaceId = localStorage.getItem(SELECTED_RACE_ID);

    if (selectedRaceId) {
      this.analyticsService.setRaceGuid(selectedRaceId);
      this.selectedRace = selectedRaceId;
    }

    if (this.demo === 'true') return;

    await this.getEventRaces();
    this.loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDsoZfoY75xr-6bUPgVNrsgFsJ59u0Yohw&libraries&v=alpha&libraries=places,geometry,maps3d,marker',
    );

    window.addEventListener('storage', (e) => this.onAuthStateChange(e));

    this.screenSizeService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  loadScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;

    document.head.appendChild(script);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const elements = document.querySelectorAll(
        '[class*="api-load-alpha-banner"]',
      );
      if (elements) {
        elements.forEach((element) => {
          this.renderer.setStyle(element, 'display', 'none');
        });
      }
    }, 250);
  }

  async getEventRaces() {
    const myEventId = localStorage.getItem('my-event-id');
    let eventId = '';

    if (myEventId) {
      eventId = myEventId;
    } else {
      eventId = this.event || '04c34783-5319-4cc7-ac8a-ee1ec7b779dc';
    }

    this.raceService.getEventRaces(eventId).subscribe((response) => {
      if (response) {
        this.races = response?.races;

        const foundRace = this.races?.length
          ? this.races.find((i) => i.guid === this.selectedRace)
          : null;

        if (response?.widget_configuration) {
          this.widgetConfiguration = response?.widget_configuration;

          this.promptColor =
            this.widgetConfiguration?.style?.main_color || '#6271FF';

          this.raceConciergeLogo = this.svgLogoService.updateSvgLogo(
            this.raceConciergeSvgTemplate,
            this.promptColor,
            '#4C57BC',
          );

          this.searchLogo = this.svgLogoService.updateSvgLogo(
            this.searchSvgTemplate,
            this.promptColor,
            '#4C57BC',
          );

          this.arrowForwardLogo = this.svgLogoService.updateSvgLogo(
            this.arrowForwardSvgTemplate,
            this.promptColor,
            '#6271FF',
          );
        }

        const mappedRaces = this.races.map((i) => {
          const orderConfig = response?.race_ordering_config?.find(
            (j) => j.guid === i.guid,
          );

          return {
            id: i?.guid || '',
            name: convertToTitleCase(i?.name || ''),
            display_order: orderConfig?.order || 0,
          };
        });

        if (!foundRace && mappedRaces.length === 1) {
          if (mappedRaces?.length) {
            const firstRace = mappedRaces.find((i) => i.display_order === 1);

            if (firstRace) {
              this.selectRace(firstRace.id);
            } else {
              this.selectRace(this.races[0].guid || '');
            }
          }
        }

        this.raceTypes = mappedRaces.sort(
          (a, b) => a.display_order - b.display_order,
        );

        if (!foundRace && mappedRaces.length > 1) {
          this.raceTypes = [
            {
              id: '1',
              name: this.translate.instant('Select a Race'),
              display_order: 0,
              is_default: true,
            },
            ...this.raceTypes,
          ];
          this.selectedRace = this.raceTypes[0].id;
        }
      }
    });
  }

  async onAuthStateChange(event: StorageEvent) {
    if (event.storageArea === localStorage || event?.type === 'storage') {
      if (event.key === 'success-login' && event.newValue) {
        if (event.newValue) {
          this.showLoginIframe = false;
          this.keycloakLoginUrl = '';
          this.isDrawerOpen = false;
          this.cdr.detectChanges();
          this.token = event.newValue;
          this.isAIDrawerOpen = true;
        }
      }
    }
  }

  private updateUserLanguage(language: string) {
    if (!this.access_token) return;
    this.userService
      .updateUserSettings({ settings: { language } }, this.access_token)
      .subscribe(() => {});
  }

  selectRace(id: string) {
    if (id) {
      this.selectedRace = id;
      localStorage.setItem(SELECTED_RACE_ID, id);
      this.analyticsService.setRaceGuid(this.selectedRace);
    }
  }

  sendMessage() {
    if (this.demo === 'true') return;
    if (this.userMessage.trim()) {
      this.isVoiceMode = false;
      this.openDrawer(this.userMessage);
      this.userMessage = '';
    }
  }

  async openVoiceInput() {
    if (this.demo === 'true') return;

    let isAccessibleMic = false;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      isAccessibleMic = true;
    } catch (error) {
      isAccessibleMic = false;
    }
    if (!isAccessibleMic) {
      this.error = 'Microphone usage has not permitted.';
      return;
    }
    this.error = '';
    this.isVoiceMode = true;
    this.openDrawer(this.userMessage);
  }

  handleChangeDrawerState(isOpen: boolean) {
    this.isDrawerOpen = isOpen;
    if (this.showLoginIframe) {
      this.isDrawerOpen = isOpen;
    } else {
      this.isAIDrawerOpen = isOpen;
    }
  }

  selectDefaultPrompt(prompt: string) {
    this.isVoiceMode = false;
    this.analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKED_QUERY, {
      prompt: prompt,
      race_guid: this.selectedRace,
    });
    this.openDrawer(prompt);
  }

  openDrawer(prompt: string) {
    if (this.demo === 'true') return;
    this.selectedPrompt = prompt;
    if (this.showLoginIframe) {
      this.isDrawerOpen = !this.isDrawerOpen;
    } else {
      this.isAIDrawerOpen = !this.isAIDrawerOpen;
    }
  }

  handleLanguageChange(language: string) {
    const formattedLanguage = language.toLowerCase();
    this.currentLanguage = formattedLanguage;
    this.translate.use(formattedLanguage);
    localStorage.setItem(SELECTED_LANGUAGE, formattedLanguage);
    this.updateUserLanguage(formattedLanguage);
  }

  handleTokenChange(token: string) {
    this.access_token = token;
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  handleRaceChange(raceId: string) {
    this.selectRace(raceId);
  }
}
