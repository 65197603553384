import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  GpxFile,
  GpxWaypoints,
  Race,
  Track,
  TrackCoordinates,
} from '../../../types/models';
import { NgClass } from '@angular/common';

interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-interactive-map',
  standalone: true,
  imports: [NgClass],
  templateUrl: './interactive-map.component.html',
  styleUrl: './interactive-map.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InteractiveMapComponent implements OnChanges {
  @Input() gpx: GpxFile | null = null;
  @Input() widget?: string | null = null;
  @Input() centerCoordinate?: TrackCoordinates | undefined = undefined;
  @Input() raceUnit: string = 'metric';
  @Input() showMap: boolean = false;
  @Input() currentRace: Race | null = null;
  @Input() isCircleRace: boolean | undefined = false;
  @Input() mode?: string = 'immersive';

  icons = {
    start: '',
    finish: '',
    aidStation: '',
    poi: '',
  };

  @ViewChild('mapContainer', { static: false })
  mapContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('map3d') map3dElement!: ElementRef;
  @ViewChild('polyline', { static: false }) polylineElement!: ElementRef;

  map: google.maps.Map | null = null;
  markers: any[] = [];
  mapInitialized: boolean = false;

  constructor() {
    this.icons.start = '../../../assets/images/start-marker.svg';
    this.icons.finish = '../../../assets/images/finish-icon.svg';
    this.icons.aidStation = '../../../assets/images/water-marker.svg';
    this.icons.poi = '../../../assets/images/point-of-interest.svg';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showMap']?.currentValue || changes['gpx']?.currentValue) {
      this.initializeImmersiveMap();
    }
  }

  public async initializeImmersiveMap(): Promise<void> {
    if (!this.gpx) return;

    // @ts-ignore
    const { PinElement } = await google.maps.importLibrary('marker');
    // @ts-ignore
    const { Marker3DElement } = await google.maps.importLibrary('maps3d');

    const map3d = this.map3dElement.nativeElement;
    this.map = map3d;
    let centerCoord = this.gpx.tracks[0]?.geometry?.coordinates[0];
    const markers: any[] = [];

    if (this.gpx?.waypoints?.length) {
      const middleWaypoint =
        this.gpx?.waypoints[Math.round((this.gpx?.waypoints.length - 1) / 2)];
      centerCoord = {
        lat: middleWaypoint.geometry?.coordinates[0].lat,
        lon: middleWaypoint.geometry.coordinates[0].lon,
        ele: middleWaypoint.geometry.coordinates[0].ele,
      };

      if (this.centerCoordinate) {
        centerCoord = this.centerCoordinate;
      }

      const startWaypointIndex = this.gpx?.waypoints
        ? this.gpx?.waypoints?.findIndex((i) => i.properties.name === 'Start')
        : -1;

      let startPosition =
        this.gpx?.waypoints[startWaypointIndex]?.geometry.coordinates[0];

      const finishWaypointIndex = this.gpx?.waypoints
        ? this.gpx?.waypoints?.findIndex((i) => i.properties.name === 'Finish')
        : -1;

      let finishPosition =
        this.gpx?.waypoints[finishWaypointIndex]?.geometry.coordinates[0];
      if (
        ((startWaypointIndex !== -1 && finishWaypointIndex === -1) ||
          this.isCircleRace) &&
        this.gpx?.waypoints
      ) {
        const coord =
          this.gpx?.waypoints[startWaypointIndex].geometry.coordinates[0];
        // @ts-ignore
        startPosition = new google.maps.LatLng(coord.lat, coord.lon);

        const glyphStartFinishUrl = `${window.location.origin}/assets/images/start-finish-icon.svg`;

        const startIconResponse = await fetch(glyphStartFinishUrl);
        const startFinishIconSvgText = await startIconResponse.text();
        const parser = new DOMParser();
        const startFinishIconSvgElement = parser.parseFromString(
          startFinishIconSvgText,
          'image/svg+xml',
        ).documentElement;

        startFinishIconSvgElement.setAttribute('width', '65');
        startFinishIconSvgElement.setAttribute('height', '65');

        const startFinishMarker = new Marker3DElement({
          position: startPosition,
        });
        const startFinishTemplate = document.createElement('template');
        startFinishTemplate.content.append(startFinishIconSvgElement);
        startFinishMarker.append(startFinishTemplate);
        map3d.append(startFinishMarker);
      } else {
        if (startWaypointIndex !== -1 && this.gpx?.waypoints) {
          const coord =
            this.gpx?.waypoints[startWaypointIndex].geometry.coordinates[0];
          // @ts-ignore
          startPosition = new google.maps.LatLng(coord.lat, coord.lon);
        }
        const glyphStartUrl = `${window.location.origin}/assets/images/start-marker1.svg`;

        const startIconResponse = await fetch(glyphStartUrl);
        const startIconSvgText = await startIconResponse.text();
        const parser = new DOMParser();
        const startIconSvgElement = parser.parseFromString(
          startIconSvgText,
          'image/svg+xml',
        ).documentElement;

        startIconSvgElement.setAttribute('width', '55');
        startIconSvgElement.setAttribute('height', '55');

        const startMarker = new Marker3DElement({ position: startPosition });
        const startTemplate = document.createElement('template');
        startTemplate.content.append(startIconSvgElement);
        startMarker.append(startTemplate);

        if (finishWaypointIndex !== -1 && this.gpx?.waypoints) {
          const coord =
            this.gpx?.waypoints[finishWaypointIndex].geometry.coordinates[0];
          // @ts-ignore
          finishPosition = new google.maps.LatLng(coord.lat, coord.lon);
        }

        const glyphFinishUrl = `${window.location.origin}/assets/images/finish-icon1.svg`;

        const finishIconResponse = await fetch(glyphFinishUrl);
        const finishIconSvgText = await finishIconResponse.text();
        const finishIconSvgElement = new DOMParser().parseFromString(
          finishIconSvgText,
          'image/svg+xml',
        ).documentElement;

        finishIconSvgElement.setAttribute('width', '55');
        finishIconSvgElement.setAttribute('height', '55');

        const finishMarker = new Marker3DElement({ position: finishPosition });
        const finishTemplate = document.createElement('template');
        finishTemplate.content.append(finishIconSvgElement);
        finishMarker.append(finishTemplate);

        map3d.append(startMarker);
        map3d.append(finishMarker);
      }

      for (const waypoint of this.gpx.waypoints) {
        const coord = waypoint.geometry.coordinates[0];

        if (waypoint.properties.type === 'AidStation') {
          const waterIconUrl = `${window.location.origin}/assets/images/aid-station-icon.svg`;

          const response = await fetch(waterIconUrl);
          const svgText = await response.text();
          const parser = new DOMParser();
          const svgElement = parser.parseFromString(
            svgText,
            'image/svg+xml',
          ).documentElement;

          svgElement.setAttribute('width', '50');
          svgElement.setAttribute('height', '50');

          const aidMarker = new Marker3DElement({
            position: { lat: coord.lat, lng: coord.lon },
          });
          const template = document.createElement('template');
          template.content.append(svgElement);
          aidMarker.append(template);
          map3d.append(aidMarker);
        } else if (waypoint.properties.type === 'DistanceMarker') {
          const unitCount = Number(waypoint.properties.name.replace(/\D/g, ''));
          const distanceMarker = await this.create3DMarkerWithText(
            { lat: coord.lat, lng: coord.lon },
            unitCount.toString(),
            '#4C57BC',
          );
          map3d.append(distanceMarker);
        }
      }
    }

    map3d.setAttribute('center', `${centerCoord.lat},${centerCoord.lon}`);

    const polyline = this.polylineElement.nativeElement;
    if (polyline) {
      const path = this.getMappedPath(this.gpx.tracks[0]);
      customElements.whenDefined(polyline.localName).then(() => {
        (polyline as any).coordinates = path;
      });
    }

    // Append all markers after the polyline
    markers.forEach((marker) => {
      this.markers.push(marker);
      map3d.append(marker);
    });

    this.mapInitialized = true;
  }

  async create3DMarkerWithText(
    position: LatLng,
    text: string,
    backgroundColor: string,
  ) {
    // @ts-ignore
    const { PinElement } = await google.maps.importLibrary('marker');
    // @ts-ignore
    const { Marker3DElement } = await google.maps.importLibrary('maps3d');

    const pinElement = new PinElement({
      glyph: text,
      background: backgroundColor,
      borderColor: 'white',
      scale: 1.2,
      glyphColor: 'white',
    });

    const marker = new Marker3DElement({
      position: new google.maps.LatLng(position.lat, position.lng),
    });

    marker.append(pinElement);

    return marker;
  }

  async create3DMarker(
    position: LatLng,
    glyphUrl: string,
    backgroundColor: string,
  ) {
    // @ts-ignore
    const { PinElement } = await google.maps.importLibrary('marker');
    // @ts-ignore
    const { Marker3DElement } = await google.maps.importLibrary('maps3d');

    const pinElement = new PinElement({
      glyph: new URL(glyphUrl),
      background: backgroundColor,
      borderColor: 'white',
      scale: 1.2,
    });

    const marker = new Marker3DElement({
      position: new google.maps.LatLng(position.lat, position.lng),
    });

    marker.append(pinElement);

    return marker;
  }

  getMappedPath(track: Track | GpxWaypoints) {
    if (track) {
      return track?.geometry?.coordinates.map((i) => ({
        lat: i.lat,
        lng: i.lon,
      }));
    } else {
      return [];
    }
  }

  haversineDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ): number {
    const R = this.raceUnit === 'metric' ? 6371 : 3958.8;
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }
}
