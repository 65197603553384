import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { RaceTypes } from '../../../types/models';
import { NgClass, NgStyle } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-race-selector',
  standalone: true,
  imports: [TranslatePipe, NgStyle, FormsModule, NgSelectModule, NgClass],
  templateUrl: './race-selector.component.html',
  styleUrl: './race-selector.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RaceSelectorComponent implements OnInit, OnChanges {
  @Input() race: string | undefined = '';
  @Input() color: string = '';
  @Input() races: RaceTypes[] | [] = [];
  @Input() isNarrowComponent: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() language?: string = '';
  @Output() raceChange = new EventEmitter<string>();
  selectedRace: string = '';
  selectedRaceName: string = '';

  constructor(
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.setRace();
  }

  onOpen() {
    setTimeout(() => {
      const panels = document?.body?.querySelectorAll('.ng-dropdown-panel');
      if (panels && panels.length > 0) {
        panels.forEach((panel) => panel.classList.add('race-select-dropdown'));
      } else {
        console.warn('No ng-dropdown-panel found.');
      }
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['color']?.currentValue) {
      const root = document.documentElement;
      root.style.setProperty('--main-color', this.color || '#6271FF');
    }
    if (changes['race']?.currentValue || changes['races']?.currentValue) {
      this.setRace();
    }

    if (changes['language']?.currentValue) {
      this.translate.use(changes['language']?.currentValue);
      this.cd.detectChanges();
      this.setRace();
    }
  }

  setRace() {
    if (this.race && this.races?.length > 0) {
      const raceFound = this.races.find((i) => i.id === this.race);
      if (raceFound) {
        this.selectedRace = raceFound.id;
        this.selectedRaceName = raceFound.name;
      }
    }
  }

  onRaceChange(event: { name: string; id: string }) {
    this.raceChange.emit(event.id);
    const raceFound = this.races.find((i) => i.id === this.race);
    if (raceFound) {
      this.selectedRaceName = raceFound.name;
    }
  }
}
