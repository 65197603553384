export const TRANSLATIONS = {
  en: {
    'Ask a race or course question': 'Ask a race or course question',
    'Ask me about course or event info': 'Ask me about course or event info',
    'Celebrate your training journey': 'Celebrate your training journey',
    'Recommend gear for event conditions':
      'Recommend gear for event conditions',
    'How does sleep impact metabolism and hormones?':
      'How does sleep impact metabolism and hormones?',
    'How can we prevent and manage fatigue?':
      'How can we prevent and manage fatigue?',
    'How do probiotics support gut health?':
      'How do probiotics support gut health?',
    'Course walkthrough': 'Course walkthrough',
    'Press to start': 'Press to start',
    Month: 'Month',
    'Set Radius': 'Set Radius',
    'Choose This Segment': 'Choose This Segment',
    'Show Map': 'Show Map',
    'Longest Run': 'Longest Run',
    'Total Distance': 'Total Distance',
    '# of Runs': '# of Runs',
    'Race Km': 'Race Km',
    'Let’s talk race day!': 'Let’s talk race day!',
    'Ask anything.': 'Ask anything.',
    Male: 'Male',
    Female: 'Female',
    'There was an error generating a response':
      'There was an error generating a response',
    'Regenerate Response': 'Regenerate Response',
    'Load more': 'Load more',
    'Ask a follow up question': 'Ask a follow up question',
    'Select Microphone:': 'Select Microphone:',
    Explore: 'Explore',
    Fueling: 'Fueling',
    'Weather conditions': 'Weather conditions',
    Chat: 'Chat',
    'Back to All AI Experts': 'Back to All AI Experts',
    'View on Immersive Map': 'View on Immersive Map',
    Map: 'Map',
    'Let’s talk race day! Ask anything.': 'Let’s talk race day! Ask anything.',
    'Keep ‘em coming! What’s next?': 'Keep ‘em coming! What’s next?',
    'Where is the start line?': 'Where is the start line?',
    'Course elevation?': 'Course elevation?',
    'When is packet pickup?': 'When is packet pickup?',
    'Weather conditions?': 'Weather conditions?',
    'Can I still register for the race?': 'Can I still register for the race?',
    "Oops! It looks like you're currently offline.":
      "Oops! It looks like you're currently offline.",
    'Please check your internet connection and try again.':
      'Please check your internet connection and try again.',
    'Oops! The CAPTCHA challenge failed.':
      'Oops! The CAPTCHA challenge failed.',
    'Please reload the page or try again later.':
      'Please reload the page or try again later.',
    'Neurun can make mistakes. Be sure to double-check important responses.':
      'Neurun can make mistakes. Be sure to double-check important responses.',
    'Please stand by, while we are checking your browser...':
      'Please stand by, while we are checking your browser...',
    'By using this service, you agree to our':
      'By using this service, you agree to our',
    'Terms & Conditions': 'Terms & Conditions',
    'Privacy Policy': 'Privacy Policy',
    'Select a Race': 'Select a Race',
    'To give you the most accurate information, let me know which race you’re interested in:':
      'To give you the most accurate information, let me know which race you’re interested in:',
    auth: {
      'Welcome back!': 'Welcome back!',
      'Let’s get you logged in.': 'Let’s get you logged in.',
      'Log in or create a free account to bookmark messages and revisit past conversations.':
        'Log in or create a free account to bookmark messages and revisit past conversations.',
      'Email Address': 'Email Address',
      Password: 'Password',
      'Forgot password?': 'Forgot password?',
      Login: 'Login',
      'Don’t have an account?': 'Don’t have an account?',
      'Create one here': 'Create one here',
      'Forgot Password': 'Forgot Password',
      'Don’t worry, it happens. Enter the email associated with your account and we’ll send you a link to reset your password.':
        'Don’t worry, it happens. Enter the email associated with your account and we’ll send you a link to reset your password.',
      'Email Sent': 'Email Sent',
      'If there’s an account associated with the email you entered, we’ve sent a link to reset your password.':
        'If there’s an account associated with the email you entered, we’ve sent a link to reset your password.',
      'Go Back to Login': 'Go Back to Login',
      'Create an account': 'Create an account',
      'Sign up for a free account to use Neurun AI.':
        'Sign up for a free account to use Neurun AI.',
      'Already have an account?': 'Already have an account?',
      'By continuing, you agree to Neurun’s':
        'By continuing, you agree to Neurun’s',
      'terms and conditions': 'terms and conditions',
      'Welcome! Let’s get to know you': 'Welcome! Let’s get to know you',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      'Create a password': 'Create a password',
      'Login Password': 'Login Password',
      'Your password must contain:': 'Your password must contain:',
      '8-32 characters long': '8-32 characters long',
      '1 lowercase character (a-z)': '1 lowercase character (a-z)',
      '1 uppercase character (A-Z)': '1 uppercase character (A-Z)',
      '1 number': '1 number',
      '1 special character e.g. !&#@$%': '1 special character e.g. !&#@$%',
      'You’re in!': 'You’re in!',
      'You now have access to all of Neurun’s personalized insight tools.':
        'You now have access to all of Neurun’s personalized insight tools.',
      'Additional prompts:': 'Additional prompts:',
      'Login here': 'Login here',
      'Invalid user credentials': 'Invalid user credentials',
      'User not found': 'User not found',
      'email must be an email': 'Email must be an email',
      'User exists with same username': 'User exists with same username',
      'Success! Your account has been created.':
        'Success! Your account has been created.',
      confirmationEmail: 'We’ve sent a confirmation email to {{email}}.',
      'View your account': 'View your account',
      'Continue conversing here': 'Continue conversing here',
    },
    actions: {
      Submit: 'Submit',
      Next: 'Next',
      'Create Account': 'Create Account',
      'Start Exploring': 'Start Exploring',
      Cancel: 'Cancel',
    },
  },
  de: {
    'Ask a race or course question':
      'Stellen Sie eine Frage zu einem Rennen oder einer Strecke',
    'Ask me about course or event info':
      'Fragen Sie mich nach Informationen zum Kurs oder Event',
    'Celebrate your training journey': 'Feiern Sie Ihre Trainingsreise',
    'Recommend gear for event conditions':
      'Empfehlen Sie Ausrüstung für Veranstaltungsbedingungen',
    'How does sleep impact metabolism and hormones?':
      'Wie wirkt sich Schlaf auf den Stoffwechsel und die Hormone aus?',
    'How can we prevent and manage fatigue?':
      'Wie können wir Müdigkeit vorbeugen und bewältigen?',
    'How do probiotics support gut health?':
      'Wie unterstützen Probiotika die Darmgesundheit?',
    'Course walkthrough': 'Kursdurchgang',
    '5K': '5K',
    '7 Mile': '7 Meile',
    '8 Mile': '8 Meile',
    '10 Mile': '10 Meile',
    '10K': '10K',
    'Half Marathon': 'Halbmarathon',
    Marathon: 'Marathon',
    'Ultra Marathon': 'Ultramarathon',
    'Relay Race': 'Staffellauf',
    'Trail Run': 'Geländelauf',
    'Cross Country': 'Crosslauf',
    'Press to start': 'Drücken zum Starten',
    Month: 'Monat',
    'Set Radius': 'Radius festlegen',
    'Choose This Segment': 'Wählen Sie dieses Segment',
    'Show Map': 'Karte anzeigen',
    'Longest Run': 'Längster Lauf',
    'Total Distance': 'Gesamtstrecke',
    '# of Runs': 'Anzahl der Läufe',
    'Race Km': 'Renn-Kilometer',
    'Let’s talk race day!': 'Lass uns über den Renntag sprechen!',
    'Ask anything.': 'Frag alles!',
    Male: 'Männlich',
    Female: 'Weiblich',
    'There was an error generating a response':
      'Beim Erzeugen der Antwort ist ein Fehler aufgetreten',
    'Regenerate Response': 'Antwort neu generieren',
    'Load more': 'Mehr laden',
    'Ask a follow up question': 'Folgefrage stellen',
    'Select Microphone:': 'Mikrofon auswählen:',
    Explore: 'Erkunden',
    Fueling: 'Tanken',
    'Weather conditions': 'Wetterbedingungen',
    Chat: 'Chat',
    'Back to All AI Experts': 'Zurück zu allen KI-Experten',
    'View on Immersive Map': 'Auf der immersiven Karte anzeigen',
    Map: 'Karte',
    'Let’s talk race day! Ask anything.':
      'Lass uns über Renntag sprechen! Frag alles.',
    'Keep ‘em coming! What’s next?': 'Immer her damit! Was kommt als Nächstes?',
    'Where is the start line?': 'Wo ist die Startlinie?',
    'Course elevation?': 'Streckenelevation?',
    'When is packet pickup?': 'Wann ist die Paketabholung?',
    'Weather conditions?': 'Wetterbedingungen?',
    'Can I still register for the race?':
      'Kann ich mich noch für das Rennen anmelden?',
    "Oops! It looks like you're currently offline.":
      'Hoppla! Es sieht so aus, als wären Sie momentan offline.',
    'Please check your internet connection and try again.':
      'Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
    'Oops! The CAPTCHA challenge failed.':
      'Hoppla! Die CAPTCHA-Prüfung ist fehlgeschlagen.',
    'Please reload the page or try again later.':
      'Bitte laden Sie die Seite neu oder versuchen Sie es später erneut.',
    'Neurun can make mistakes. Be sure to double-check important responses.':
      'Neurun kann Fehler machen. Überprüfen Sie wichtige Antworten unbedingt noch einmal.',
    'Please stand by, while we are checking your browser...':
      'Bitte warten Sie, während wir Ihren Browser überprüfen…',
    'By using this service, you agree to our':
      'Durch die Nutzung dieses Dienstes stimmen Sie unseren',
    'Terms & Conditions': 'Nutzungsbedingungen',
    'Privacy Policy': 'Datenschutzrichtlinie',
    'Select a Race': 'Wähle ein Rennen',
    'To give you the most accurate information, let me know which race you’re interested in:':
      'Damit ich Ihnen die genauesten Informationen geben kann, teilen Sie mir bitte mit, an welchem Rennen Sie interessiert sind:',
    auth: {
      'Welcome back!': 'Willkommen zurück!',
      'Let’s get you logged in.': 'Lassen Sie uns Sie einloggen.',
      'Log in or create a free account to bookmark messages and revisit past conversations.':
        'Melden Sie sich an oder erstellen Sie ein kostenloses Konto, um Nachrichten zu speichern und vergangene Unterhaltungen erneut aufzurufen.',
      'Email Address': 'E-Mail-Adresse',
      Password: 'Passwort',
      'Forgot password?': 'Passwort vergessen?',
      Login: 'Anmelden',
      'Don’t have an account?': 'Kein Konto?',
      'Create one here': 'Erstellen Sie hier eines',
      'Forgot Password': 'Passwort vergessen',
      'Don’t worry, it happens. Enter the email associated with your account and we’ll send you a link to reset your password.':
        'Keine Sorge, das passiert. Geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein, und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
      'Email Sent': 'E-Mail gesendet',
      'If there’s an account associated with the email you entered, we’ve sent a link to reset your password.':
        'Falls es ein Konto mit der von Ihnen eingegebenen E-Mail-Adresse gibt, haben wir einen Link zum Zurücksetzen Ihres Passworts gesendet.',
      'Go Back to Login': 'Zurück zur Anmeldung',
      'Create an account': 'Konto erstellen',
      'Sign up for a free account to use Neurun AI.':
        'Registrieren Sie sich für ein kostenloses Konto, um Neurun AI zu nutzen.',
      'Already have an account?': 'Haben Sie bereits ein Konto?',
      'By continuing, you agree to Neurun’s':
        'Mit der Fortsetzung stimmen Sie den',
      'terms and conditions': 'Geschäftsbedingungen',
      'Welcome! Let’s get to know you': 'Willkommen! Lernen wir Sie kennen',
      'First Name': 'Vorname',
      'Last Name': 'Nachname',
      'Create a password': 'Erstellen Sie ein Passwort',
      'Login Password': 'Anmeldepasswort',
      'Your password must contain:': 'Ihr Passwort muss enthalten:',
      '8-32 characters long': '8-32 Zeichen lang',
      '1 lowercase character (a-z)': '1 Kleinbuchstabe (a-z)',
      '1 uppercase character (A-Z)': '1 Großbuchstabe (A-Z)',
      '1 number': '1 Zahl',
      '1 special character e.g. !&#@$%': '1 Sonderzeichen, z.B. !&#@$%',
      'You’re in!': 'Sie sind drin!',
      'You now have access to all of Neurun’s personalized insight tools.':
        'Sie haben jetzt Zugriff auf alle personalisierten Einblick-Tools von Neurun.',
      'Additional prompts:': 'Weitere Aufforderungen:',
      'Login here': 'Hier einloggen',
      'Invalid user credentials': 'Ungültige Benutzeranmeldedaten',
      'User not found': 'Benutzer nicht gefunden',
      'email must be an email': 'E-Mail muss eine gültige E-Mail-Adresse sein',
      'User exists with same username':
        'Ein Benutzer mit demselben Benutzernamen existiert bereits',
      'Success! Your account has been created.':
        'Erfolg! Ihr Konto wurde erstellt.',
      confirmationEmail: 'Wir haben eine Bestätigungs‑E‑Mail an {{email}}.',
      'View your account': 'Ihr Konto anzeigen',
      'Continue conversing here': 'Fahren Sie hier mit der Unterhaltung fort',
    },
    actions: {
      Submit: 'Absenden',
      Next: 'Weiter',
      'Create Account': 'Konto erstellen',
      'Start Exploring': 'Jetzt entdecken',
      Cancel: 'Abbrechen',
    },
  },
  fr: {
    'Ask a race or course question':
      'Posez une question sur une course ou un parcours',
    'Ask me about course or event info':
      "Demandez-moi des informations sur le parcours ou l'événement",
    'Celebrate your training journey': "Célébrez votre parcours d'entraînement",
    'Recommend gear for event conditions':
      "Recommandez du matériel pour les conditions de l'événement",
    'How does sleep impact metabolism and hormones?':
      'Comment le sommeil influence-t-il le métabolisme et les hormones ?',
    'How can we prevent and manage fatigue?':
      'Comment pouvons-nous prévenir et gérer la fatigue ?',
    'How do probiotics support gut health?':
      'Comment les probiotiques soutiennent-ils la santé intestinale ?',
    'Course walkthrough': 'Parcours du cours',
    '5K': '5K',
    '7 Mile': '7 miles',
    '8 Mile': '8 miles',
    '10 Mile': '10 miles',
    '10K': '10K',
    'Half Marathon': 'Semi-marathon',
    Marathon: 'Marathon',
    'Ultra Marathon': 'Ultra-marathon',
    'Relay Race': 'Course de relais',
    'Trail Run': 'Course de trail',
    'Cross Country': 'Cross-country',
    'Press to start': 'Appuyez pour démarrer',
    Month: 'Mois',
    'Set Radius': 'Définir le rayon',
    'Choose This Segment': 'Choisissez ce segment',
    'Show Map': 'Afficher la carte',
    'Longest Run': 'Course la plus longue',
    'Total Distance': 'Distance totale',
    '# of Runs': "Posez n'importe quoi!",
    'Race Km': 'Km de course',
    'Let’s talk race day!': 'Parlons du jour de la course!',
    'Ask anything.': 'Posez n importe quoi!',
    Male: 'Homme',
    Female: 'Femme',
    'There was an error generating a response':
      "Une erreur s'est produite lors de la génération de la réponse",
    'Regenerate Response': 'Régénérer la réponse',
    'Load more': 'Charger plus',
    'Ask a follow up question': 'Poser une question de suivi',
    'Select Microphone:': 'Sélectionner le microphone:',
    Explore: 'Explorer',
    Fueling: 'Alimentation',
    'Weather conditions': 'Conditions météorologiques',
    Chat: 'Chat',
    'Back to All AI Experts': 'Retour à tous les experts en IA',
    'View on Immersive Map': 'Voir sur la carte immersive',
    Map: 'Carte',
    'Let’s talk race day! Ask anything.':
      'Parlons du jour de la course! Posez vos questions.',
    'Keep ‘em coming! What’s next?': 'Continuez comme ça! Quoi de neuf?',
    'Where is the start line?': 'Où se trouve la ligne de départ ?',
    'Course elevation?': 'Dénivelé du parcours ?',
    'When is packet pickup?': 'Quand récupérer son dossard ?',
    'Weather conditions?': 'Conditions météorologiques ?',
    'Can I still register for the race?':
      "Puis-je encore m'inscrire à la course ?",
    "Oops! It looks like you're currently offline.":
      'Oups ! Il semble que vous soyez actuellement hors ligne.',
    'Please check your internet connection and try again.':
      'Veuillez vérifier votre connexion Internet et réessayer.',
    'Oops! The CAPTCHA challenge failed.': 'Oups ! Le test CAPTCHA a échoué.',
    'Please reload the page or try again later.':
      'Veuillez recharger la page ou réessayer plus tard.',
    'Neurun can make mistakes. Be sure to double-check important responses.':
      'Neurun peut faire des erreurs. Assurez-vous de vérifier à nouveau les réponses importantes.',
    'Please stand by, while we are checking your browser...':
      'Veuillez patienter pendant que nous vérifions votre navigateur…',
    'By using this service, you agree to our':
      'En utilisant ce service, vous acceptez nos',
    'Terms & Conditions': 'Conditions générales',
    'Privacy Policy': 'Politique de confidentialité',
    'Select a Race': 'Sélectionnez une course',
    'To give you the most accurate information, let me know which race you’re interested in:':
      'Pour vous fournir les informations les plus précises, faites-moi savoir quelle course vous intéresse:',
    auth: {
      'Welcome back!': 'Bon retour !',
      'Let’s get you logged in.': 'Connectons-nous.',
      'Log in or create a free account to bookmark messages and revisit past conversations.':
        "Connectez-vous ou créez un compte gratuit pour enregistrer des messages et revenir sur d'anciennes conversations.",
      'Email Address': 'Adresse e-mail',
      Password: 'Mot de passe',
      'Forgot password?': 'Mot de passe oublié ?',
      Login: 'Connexion',
      'Don’t have an account?': "Vous n'avez pas de compte ?",
      'Create one here': 'Créez-en un ici',
      'Forgot Password': 'Mot de passe oublié',
      'Don’t worry, it happens. Enter the email associated with your account and we’ll send you a link to reset your password.':
        "Ne vous inquiétez pas, cela arrive. Entrez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
      'Email Sent': 'E-mail envoyé',
      'If there’s an account associated with the email you entered, we’ve sent a link to reset your password.':
        "S'il existe un compte associé à l'adresse e-mail que vous avez saisie, nous vous avons envoyé un lien pour réinitialiser votre mot de passe.",
      'Go Back to Login': 'Retour à la connexion',
      'Create an account': 'Créer un compte',
      'Sign up for a free account to use Neurun AI.':
        "Inscrivez-vous pour un compte gratuit afin d'utiliser Neurun AI.",
      'Already have an account?': 'Vous avez déjà un compte ?',
      'By continuing, you agree to Neurun’s':
        'En continuant, vous acceptez les',
      'terms and conditions': 'termes et conditions',
      'Welcome! Let’s get to know you':
        'Bienvenue ! Apprenons à vous connaître',
      'First Name': 'Prénom',
      'Last Name': 'Nom de famille',
      'Create a password': 'Créez un mot de passe',
      'Login Password': 'Mot de passe de connexion',
      'Your password must contain:': 'Votre mot de passe doit contenir :',
      '8-32 characters long': 'Entre 8 et 32 caractères',
      '1 lowercase character (a-z)': '1 lettre minuscule (a-z)',
      '1 uppercase character (A-Z)': '1 lettre majuscule (A-Z)',
      '1 number': '1 chiffre',
      '1 special character e.g. !&#@$%':
        '1 caractère spécial, par exemple : !&#@$%',
      'You’re in!': 'Vous êtes connecté !',
      'You now have access to all of Neurun’s personalized insight tools.':
        'Vous avez désormais accès à tous les outils personnalisés de Neurun.',
      'Additional prompts:': 'Prompts supplémentaires :',
      'Login here': 'Connectez-vous ici',
      'Invalid user credentials': 'Identifiants utilisateur invalides',
      'User not found': 'Utilisateur non trouvé',
      'email must be an email': "L'adresse e-mail doit être valide",
      'User exists with same username':
        "Un utilisateur avec le même nom d'utilisateur existe déjà",
      'Success! Your account has been created.':
        'Succès ! Votre compte a été créé.',
      confirmationEmail:
        'Nous avons envoyé un e-mail de confirmation à {{email}}.',
      'View your account': 'Voir votre compte',
      'Continue conversing here': 'Continuez à converser ici',
    },
    actions: {
      Submit: 'Soumettre',
      Next: 'Suivant',
      'Create Account': 'Créer un compte',
      'Start Exploring': 'Commencer à explorer',
      Cancel: 'Annuler',
    },
  },
  es: {
    'Ask a race or course question': 'Pregunta sobre una carrera o curso',
    'Ask me about course or event info':
      'Pregúntame sobre información de cursos o eventos',
    'Celebrate your training journey': 'Celebra tu camino de entrenamiento',
    'Recommend gear for event conditions':
      'Recomienda equipo para las condiciones del evento',
    'How does sleep impact metabolism and hormones?':
      '¿Cómo afecta el sueño al metabolismo y a las hormonas?',
    'How can we prevent and manage fatigue?':
      '¿Cómo podemos prevenir y manejar la fatiga?',
    'How do probiotics support gut health?':
      '¿Cómo apoyan los probióticos la salud intestinal?',
    'Course walkthrough': 'Recorrido del Curso',
    '5K': '5K',
    '7 Mile': '7 millas',
    '8 Mile': '8 millas',
    '10 Mile': '10 millas',
    '10K': '10K',
    'Half Marathon': 'Media maratón',
    Marathon: 'Maratón',
    'Ultra Marathon': 'Ultramaratón',
    'Relay Race': 'Carrera de relevos',
    'Trail Run': 'Carrera de montaña',
    'Cross Country': 'Carrera de campo',
    'Press to start': 'Presiona para comenzar',
    Month: 'Mes',
    'Set Radius': 'Establecer radio',
    'Choose This Segment': 'Selecciona este segmento',
    'Show Map': 'Mostrar mapa',
    'Longest Run': 'Carrera más larga',
    'Total Distance': 'Distancia total',
    '# of Runs': 'Número de carreras',
    'Race Km': 'Km de carrera',
    'Let’s talk race day!': '¡Hablemos del día de la carrera!',
    'Ask anything.': '¡Pregunta lo que sea!',
    Male: 'Masculino',
    Female: 'Femenino',
    'There was an error generating a response':
      'Hubo un error al generar una respuesta',
    'Regenerate Response': 'Regenerar respuesta',
    'Load more': 'Cargar más',
    'Ask a follow up question': 'Haz una pregunta de seguimiento',
    'Select Microphone:': 'Seleccionar micrófono:',
    Explore: 'Explorar',
    Fueling: 'Alimentando',
    'Weather conditions': 'Condiciones meteorológicas',
    Chat: 'Chat',
    'Back to All AI Experts': 'Volver a todos los expertos en IA',
    'View on Immersive Map': 'Ver en el mapa inmersivo',
    Map: 'Mapa',
    'Let’s talk race day! Ask anything.':
      '¡Hablemos del día de la carrera! Pregunta lo que quieras.',
    'Keep ‘em coming! What’s next?': '¡Sigan así! ¿Qué sigue?',
    'Where is the start line?': '¿Dónde está la línea de salida?',
    'Course elevation?': '¿Elevación del recorrido?',
    'When is packet pickup?': '¿Cuándo es la recogida de paquetes?',
    'Weather conditions?': '¿Condiciones meteorológicas?',
    'Can I still register for the race?':
      '¿Todavía puedo inscribirme en la carrera?',
    "Oops! It looks like you're currently offline.":
      '¡Vaya! Parece que estás desconectado en este momento.',
    'Please check your internet connection and try again.':
      'Por favor, comprueba tu conexión a Internet y vuelve a intentarlo.',
    'Oops! The CAPTCHA challenge failed.': '¡Vaya! El desafío CAPTCHA falló.',
    'Please reload the page or try again later.':
      'Por favor, recarga la página o inténtalo de nuevo más tarde.',
    'Neurun can make mistakes. Be sure to double-check important responses.':
      'Neurun puede cometer errores. Asegúrese de verificar dos veces las respuestas importantes.',
    'Please stand by, while we are checking your browser...':
      'Por favor, espere mientras verificamos su navegador…',
    'By using this service, you agree to our':
      'Al usar este servicio, usted acepta nuestros',
    'Terms & Conditions': 'Términos y condiciones',
    'Privacy Policy': 'Política de privacidad',
    'Select a Race': 'Selecciona una carrera',
    'To give you the most accurate information, let me know which race you’re interested in:':
      'Para brindarte la información más precisa, indícame en qué carrera estás interesado:',
    auth: {
      'Welcome back!': '¡Bienvenido de nuevo!',
      'Let’s get you logged in.': 'Vamos a iniciar sesión.',
      'Log in or create a free account to bookmark messages and revisit past conversations.':
        'Inicia sesión o crea una cuenta gratuita para marcar mensajes y volver a visitar conversaciones anteriores.',
      'Email Address': 'Dirección de correo electrónico',
      Password: 'Contraseña',
      'Forgot password?': '¿Olvidaste tu contraseña?',
      Login: 'Iniciar sesión',
      'Don’t have an account?': '¿No tienes una cuenta?',
      'Create one here': 'Crea una aquí',
      'Forgot Password': 'Olvidar contraseña',
      'Don’t worry, it happens. Enter the email associated with your account and we’ll send you a link to reset your password.':
        'No te preocupes, esto pasa. Ingresa el correo asociado a tu cuenta y te enviaremos un enlace para restablecer tu contraseña.',
      'Email Sent': 'Correo enviado',
      'If there’s an account associated with the email you entered, we’ve sent a link to reset your password.':
        'Si hay una cuenta asociada al correo ingresado, te hemos enviado un enlace para restablecer tu contraseña.',
      'Go Back to Login': 'Regresar al inicio de sesión',
      'Create an account': 'Crear una cuenta',
      'Sign up for a free account to use Neurun AI.':
        'Regístrate para una cuenta gratuita y usa Neurun AI.',
      'Already have an account?': '¿Ya tienes una cuenta?',
      'By continuing, you agree to Neurun’s': 'Al continuar, aceptas los',
      'terms and conditions': 'términos y condiciones',
      'Welcome! Let’s get to know you':
        '¡Bienvenido! Conozcamos un poco sobre ti',
      'First Name': 'Nombre',
      'Last Name': 'Apellido',
      'Create a password': 'Crea una contraseña',
      'Login Password': 'Contraseña de inicio de sesión',
      'Your password must contain:': 'Tu contraseña debe contener:',
      '8-32 characters long': 'Entre 8 y 32 caracteres',
      '1 lowercase character (a-z)': '1 letra minúscula (a-z)',
      '1 uppercase character (A-Z)': '1 letra mayúscula (A-Z)',
      '1 number': '1 número',
      '1 special character e.g. !&#@$%':
        '1 carácter especial, por ejemplo: !&#@$%',
      'You’re in!': '¡Has entrado!',
      'You now have access to all of Neurun’s personalized insight tools.':
        'Ahora tienes acceso a todas las herramientas personalizadas de Neurun.',
      'Additional prompts:': 'Prompts adicionales:',
      'Login here': 'Inicia sesión aquí',
      'Invalid user credentials': 'Credenciales de usuario inválidas',
      'User not found': 'Usuario no encontrado',
      'email must be an email': 'El correo electrónico debe ser válido',
      'User exists with same username':
        'Ya existe un usuario con el mismo nombre de usuario',
      'Success! Your account has been created.':
        '¡Éxito! Tu cuenta ha sido creada.',
      confirmationEmail:
        'Hemos enviado un correo electrónico de confirmación a {{email}}.',
      'View your account': 'Ver tu cuenta',
      'Continue conversing here': 'Continúa conversando aquí',
    },
    actions: {
      Submit: 'Enviar',
      Next: 'Siguiente',
      'Create Account': 'Crear cuenta',
      'Start Exploring': 'Comienza a explorar',
      Cancel: 'Cancelar',
    },
  },
  ja: {
    'Ask a race or course question': 'レースまたはコースに関する質問をする',
    'Ask me about course or event info':
      'コースやイベント情報について質問してください',
    'Celebrate your training journey': 'あなたのトレーニングの旅を祝いましょう',
    'Recommend gear for event conditions':
      'イベントの状況に合わせた装備をおすすめします',
    'How does sleep impact metabolism and hormones?':
      '睡眠は代謝やホルモンにどのような影響を与えますか？',
    'How can we prevent and manage fatigue?':
      'どのようにして疲労を予防し、管理できますか？',
    'How do probiotics support gut health?':
      'プロバイオティクスは腸内環境の健康をどのようにサポートしますか？',
    'Course walkthrough': 'コースのウォークスルー',
    '5K': '5K',
    '7 Mile': '7マイル',
    '8 Mile': '8マイル',
    '10 Mile': '10マイル',
    '10K': '10K',
    'Half Marathon': 'ハーフマラソン',
    Marathon: 'マラソン',
    'Ultra Marathon': 'ウルトラマラソン',
    'Relay Race': 'リレーレース',
    'Trail Run': 'トレイルラン',
    'Cross Country': 'クロスカントリー',
    'Press to start': '開始するには押してください',
    Month: '月',
    'Set Radius': '半径を設定する',
    'Choose This Segment': 'このセグメントを選択する',
    'Show Map': '地図を表示する',
    'Longest Run': '最長のラン',
    'Total Distance': '総距離',
    '# of Runs': '走行回数',
    'Race Km': 'レースキロメートル',
    'Let’s talk race day!': 'レース当日について話しましょう！',
    'Ask anything.': '何でも聞いてください！',
    Male: '男性',
    Female: '女性',
    'There was an error generating a response':
      '回答の生成中にエラーが発生しました',
    'Regenerate Response': '回答を再生成する',
    'Load more': 'もっと読み込む',
    'Ask a follow up question': 'フォローアップの質問をする',
    'Select Microphone:': 'マイクを選択:',
    Explore: '探検する',
    Fueling: '燃料補給',
    'Weather conditions': '天候',
    Chat: 'チャット',
    'Back to All AI Experts': 'すべてのAI専門家に戻る',
    'View on Immersive Map': 'イマーシブマップで表示',
    Map: '地図',
    'Let’s talk race day! Ask anything.':
      'レースデーについて話そう！何でも聞いて。',
    'Keep ‘em coming! What’s next?': 'どんどんどうぞ！次は何？',
    'Where is the start line?': 'スタートラインはどこですか？',
    'Course elevation?': 'コースの標高は？',
    'When is packet pickup?': 'パケットの受け取りはいつですか？',
    'Weather conditions?': '天候は？',
    'Can I still register for the race?': 'まだレースに登録できますか？',
    "Oops! It looks like you're currently offline.":
      'おっと！ 現在オフラインのようです。',
    'Please check your internet connection and try again.':
      'インターネット接続を確認して、もう一度お試しください。',
    'Oops! The CAPTCHA challenge failed. Please reload the page or try again later.':
      'おっと！ CAPTCHAチャレンジに失敗しました。ページを再読み込みするか、後でもう一度お試しください。',
    'Neurun can make mistakes. Be sure to double-check important responses.':
      'Neurun は間違いをする可能性があります。重要な回答は必ず再確認してください。',
    'Please stand by, while we are checking your browser...':
      '少々お待ちください。ブラウザを確認しています…',
    'By using this service, you agree to our':
      '本サービスを利用することで、当社の～に同意したものとみなされます',
    'Terms & Conditions': '利用規約',
    'Privacy Policy': 'プライバシーポリシー',
    'Select a Race': 'レースを選択',
    'To give you the most accurate information, let me know which race you’re interested in:':
      '最も正確な情報を提供するために、どのレースにご興味があるのか教えてください:',
    auth: {
      'Welcome back!': 'おかえりなさい！',
      'Let’s get you logged in.': 'ログインしましょう。',
      'Log in or create a free account to bookmark messages and revisit past conversations.':
        'メッセージをブックマークし、過去の会話を再訪するには、ログインするか無料アカウントを作成してください。',
      'Email Address': 'メールアドレス',
      Password: 'パスワード',
      'Forgot password?': 'パスワードをお忘れですか？',
      Login: 'ログイン',
      'Don’t have an account?': 'アカウントをお持ちでないですか？',
      'Create one here': 'こちらで作成',
      'Forgot Password': 'パスワードを忘れた',
      'Don’t worry, it happens. Enter the email associated with your account and we’ll send you a link to reset your password.':
        'ご安心ください。アカウントに登録されているメールアドレスを入力すると、パスワードリセット用のリンクを送信します。',
      'Email Sent': 'メール送信済み',
      'If there’s an account associated with the email you entered, we’ve sent a link to reset your password.':
        '入力されたメールアドレスに関連付けられたアカウントがある場合、パスワードリセット用のリンクを送信しました。',
      'Go Back to Login': 'ログインに戻る',
      'Create an account': 'アカウントを作成する',
      'Sign up for a free account to use Neurun AI.':
        'Neurun AI を利用するために無料アカウントに登録してください.',
      'Already have an account?': 'すでにアカウントをお持ちですか？',
      'By continuing, you agree to Neurun’s': '続行することで、Neurunの',
      'terms and conditions': '利用規約',
      'Welcome! Let’s get to know you': 'ようこそ！あなたのことを知りましょう',
      'First Name': '名',
      'Last Name': '姓',
      'Create a password': 'パスワードを作成する',
      'Login Password': 'ログインパスワード',
      'Your password must contain:':
        'パスワードには以下を含める必要があります：',
      '8-32 characters long': '8～32文字で',
      '1 lowercase character (a-z)': '小文字（a-z）を1文字',
      '1 uppercase character (A-Z)': '大文字（A-Z）を1文字',
      '1 number': '数字を1文字',
      '1 special character e.g. !&#@$%': '記号（例：!&#@$%）を1文字',
      'You’re in!': 'ログイン成功！',
      'You now have access to all of Neurun’s personalized insight tools.':
        'これで、Neurunのパーソナライズされたインサイトツールすべてにアクセスできます。',
      'Additional prompts:': '追加のプロンプト：',
      'Login here': 'ここでログイン',
      'Invalid user credentials': '無効なユーザー認証情報',
      'User not found': 'ユーザーが見つかりません',
      'email must be an email':
        'メールは有効なメールアドレスである必要があります',
      'User exists with same username':
        '同じユーザー名のユーザーが既に存在します',
      'Success! Your account has been created.':
        '成功しました！アカウントが作成されました。',
      confirmationEmail: '確認メールを送信しました {{email}}.',
      'View your account': 'アカウントを見る',
      'Continue conversing here': 'ここで会話を続ける',
    },
    actions: {
      Submit: '送信',
      Next: '次へ',
      'Create Account': 'アカウント作成',
      'Start Exploring': '探索を開始',
      Cancel: 'キャンセル',
    },
  },
};
