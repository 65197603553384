<div
  [ngStyle]="{
    'opacity': disabled ? 0.5 : 1,
    'cursor': disabled ? 'not-allowed' : 'pointer'
  }"
>
  <ng-select
    [items]="languages"
    bindLabel="label"
    bindValue="code"
    class="ng-select custom-language"
    [clearable]="false"
    [searchable]="false"
    [appendTo]="'body'"
    (open)="onOpen()"
    [(ngModel)]="selectedLanguage"
    (change)="onLanguageChange($event)"
  >
  <ng-template ng-label-tmp let-item="item">
    {{ item.code | uppercase }}
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    {{ item.label }}
  </ng-template>
  </ng-select>
</div>
